// event style
.event_sec {
  .event_tab_list {
    padding: 1.6rem 3.2rem;
    @include media_query(one_extra_large) {
      min-height: 12rem;
      position: relative;
    }
    @include media_query(medium) {
      padding-inline: 1rem;
    }
    @include media_query(small) {
      padding-inline: 1.5rem;
    }
    .tab_list {
      gap: 0.8rem;
      @include media_query(medium) {
        gap: 1rem;
      }
      @include media_query(small) {
        gap: 1rem;
      }
      li {
        @extend .list_unstyled;
        white-space: nowrap;
        font-size: $fs18;
        @extend .montserrat_medium;
        color: $gray-two;
        padding: 0.8rem 1.5rem;
        @extend .cursor_pointer;
        max-width: 21.2rem;
        @extend .w_100;
        @extend .text_center;
        @include media_query(three_extra_large) {
          max-width: 18.5rem;
          padding-inline: 1rem;
        }
        @include media_query(extra_large) {
          font-size: 1.5rem;
          max-width: 16.5rem;
        }
        @include media_query(medium) {
          font-size: $fs12;
          max-width: 16rem;
          padding-inline: 0.3rem;
        }
        @include media_query(small) {
          font-size: $fs14;
          max-width: 100%;
          width: auto;
          padding-inline: 0.6rem;
        }
        @include media_query(extra_small) {
          font-size: $fs12;
        }
        &.active {
          background: $form-input-active-bg;
          border-bottom: 0.4rem solid $btn_primary !important;
          color: $primary-color;
          border-radius: 8px 8px 2px 2px;
          @extend .montserrat_bold;
        }
      }
      .common_menu {
        @extend .d_flex;
        @extend .align_items_center;
        @extend .justify_content_end;
        margin-left: auto;
        gap: 1.6rem;
        @include media_query(medium) {
          gap: 0.5rem;
        }
        .add_plus_btn {
          white-space: nowrap;
          width: sum(50%, 50%);

          .btn_primary {
            padding: 0.9rem 3rem;
            @extend .d_flex_align;
            // &:hover{
            //   color: $white-color;
            //   .plus_icon {
            //     img{
            //       filter: brightness(21) !important;
            //     }
            //   }
            // }
            @include media_query(large) {
              background-color: $white-color;
              border: 0.1rem solid $btn_disable;
              width: 4rem;
              height: 4rem;
              display: grid;
              place-items: center;
              padding: 0;
              border-radius: 50%;
              @media (hover: hover) {
                &:hover {
                  background-color: $primary-color;
                  .plus_icon {
                    filter: brightness(10);
                  }
                }
              }
            }
            .add_btn_name {
              padding-right: 1.2rem;
              @include media_query(large) {
                display: none;
              }
              @include media_query(small) {
                display: none;
              }
            }
            .plus_icon {
              width: 1.5rem;
              height: 1.5rem;
              @include media_query(large) {
                color: $secondary-color;
                background-image: url("../images/btn-plus.svg");
                width: 2rem;
                height: 2rem;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                img {
                  display: none;
                }
              }
              img {
                @include trans;
              }
            }
            &:hover {
              .plus_icon {
                img {
                  filter: brightness(21);
                  @include trans;
                }
              }
            }
            &.disable {
              background-color: $grey-text;
              user-select: none;
              cursor: not-allowed;
            }
            @include media_query(three_extra_large) {
              padding: 0.9rem 1rem;
              min-width: 4rem;
            }
          }
        }
      }
    }
    .custom_searchbox {
      form {
        margin-top: 0;
        @include media_query(three_extra_large) {
          margin-left: 1.2rem;
        }
        @include media_query(small) {
          max-width: 100%;
        }
      }
      @include media_query(one_extra_large) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-inline: 3.2rem;
        @include media_query(small) {
          right: unset;
        }
        form {
          margin-left: 0;
          max-width: 100%;
          @include media_query(extra_small) {
            margin-right: 0;
          }
        }
      }
      @include media_query(medium) {
        padding-inline: 1rem;
      }
      @include media_query(small) {
        padding-inline: 2.4rem;
        width: 100%;
      }

      // input[type="search"]::-webkit-search-cancel-button {
      //   appearance: none;
      //   background: url("../images/serch_list_remove_icon.svg") no-repeat center;
      //   object-fit: contain;
      //   width: 2.5rem;
      //   height: 2.5rem;
      //   @extend .cursor_pointer;
      // }
    }
    &.content_fixed_header {
      @include media_query(small) {
        position: fixed;
        width: 100%;
        background-color: $white-color;
        z-index: 1;
        top: 5.5rem;
      }
    }
  }
  .event_list {
    @extend .d_flex;
    gap: 3.2rem;
    margin-top: 2.5rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
    // padding-bottom: 12rem;
    padding-bottom: 3.2rem;
    padding-right: 1rem;
    align-items: start;
    width: sum(10%, 30%, 55%, 5%);
    @include media_query(three_extra_large) {
      padding-right: 0;
    }
    @media screen and (max-width: 1370px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    @include media_query(two_extra_large) {
      gap: 2rem;
    }
    @include media_query(extra_large) {
      grid-template-columns: 1fr 1fr;
      gap: 3.2rem;
    }

    @extend .flex_wrap;
    @include media_query(medium) {
      margin: 2.5rem;
      grid-template-columns: 1fr;
    }
    @include media_query(small) {
      justify-content: center;
      padding-top: 17.5rem;
    }
    .event_box {
      border: 0.1rem solid $btn_disable;
      box-shadow: 0 0.4rem 4rem 0 rgba(36, 73, 137, 0.08);
      @extend .w_100;
      max-width: 31.4rem;
      border-radius: 0.8rem;
      @extend .position_relative;
      -webkit-tap-highlight-color: transparent;
      background-color: $white-color;
      // padding-bottom: 2rem;
      &.favourite_event {
        padding-bottom: 8rem;
      }
      &.event_box_small {
        min-height: auto;
        padding-bottom: 2rem;
      }
      @media screen and (max-width: 1370px) {
        max-width: 100%;
      }
      @include media_query(medium) {
        max-width: 38.2rem;
        min-height: 40rem;
        padding-bottom: 7rem;
      }
      .like_img {
        @extend .cursor_pointer;
        background-color: $white-color;
        @extend .w_100;
        max-width: 4rem;
        height: 4rem;
        @extend .d_flex_center;
        @extend .position_absolute;
        border-radius: 50px;
        top: 198px;
        right: 1.7rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transform: translateY(-50%);
        margin-top: -2rem;
      }
      &.favourite_event {
        @extend .position_relative;
        .event_detail {
          .event_location {
            position: inherit;
          }
        }
      }
      .event_detail {
        @extend .cursor_pointer;
        .event_padding {
          padding: 2.5rem 1.7rem 0rem 1.7rem;
          .name_toolkit {
            position: relative;
            &:hover {
              .full_username {
                display: flex;
                align-items: center;
              }
            }
            .status_tab {
              background: #f4f8fd;
              margin-left: 8px;
              line-height: normal;
              color: #222222;
              padding: 0.5rem 1.2rem;
              min-height: 3rem;
              vertical-align: middle;
              display: inline-block;
              border-radius: 0.6rem;
              font-size: 1.5rem;
              font-weight: bold;
            }
            .full_username {
              left: unset !important;
              right: 50% !important;
              transform: translate(50%, 0px);
              line-height: 140%;
              padding: 0.8rem 1rem;
              background: #121b3d;
              color: #ffffff;
              gap: 1rem;
              top: -3.7rem;
              left: 0;
              right: auto;
              z-index: 9;
              display: none;
              border-radius: 0.4rem;
              white-space: nowrap;
              cursor: pointer;
              position: absolute;
              font-size: 1.4rem;
              font-family: "Montserrat_regular";
              font-weight: 400;
              z-index: 9999 !important;
            }
          }
        }
        .mentor_rights {
          padding: 2.4rem 1.7rem 0;
          .mentor_rt_title {
            h5 {
              color: $gray-three;
              font-size: $fs16;
              @extend .montserrat_regular;
              margin-bottom: 1rem;
              line-height: 1.22;
            }
          }
          .mentor_status_list {
            ul {
              flex-grow: 1;
              li {
                color: $gray-three;
                font-size: $fs16;
                @extend .montserrat_medium;
                font-weight: $fw500;
                &:not(:last-child) {
                  margin: 0 0 4px;
                }
                span {
                  @extend .montserrat_bold;
                  color: $primary-color;
                }
              }
            }
            .more_mentor_status {
              flex-shrink: 0;
              a {
                min-width: 45px;
                padding: 7px;
                font-size: $fs12;
                @extend .montserrat_medium;
                color: $btn_primary;
                border: 1px solid $thub_border;
                background: $hr-color;
                border-radius: 70px;
                @extend .d_block;
                @extend .text_center;
                span {
                  margin-left: 1px;
                }
              }
            }
          }
        }
        .mentor_rights {
          padding: 2.4rem 1.7rem 0;
          .mentor_rt_title {
            h5 {
              color: $gray-three;
              font-size: $fs16;
              @extend .montserrat_regular;
              margin-bottom: 1rem;
              line-height: 1.22;
            }
          }
          .mentor_status_list {
            ul {
              flex-grow: 1;
              li {
                color: $gray-three;
                font-size: $fs16;
                @extend .montserrat_medium;
                font-weight: $fw500;
                &:not(:last-child) {
                  margin: 0 0 4px;
                }
                span {
                  @extend .montserrat_bold;
                  color: $primary-color;
                }
              }
            }
            .more_mentor_status {
              flex-shrink: 0;
              a {
                min-width: 45px;
                padding: 7px;
                font-size: $fs12;
                @extend .montserrat_medium;
                color: $btn_primary;
                border: 1px solid $thub_border;
                background: $hr-color;
                border-radius: 70px;
                @extend .d_block;
                @extend .text_center;
                span {
                  margin-left: 1px;
                }
              }
            }
          }
        }
        .mentor_rights {
          padding: 2.4rem 1.7rem 0;
          .mentor_rt_title {
            h5 {
              color: $gray-three;
              font-size: $fs16;
              @extend .montserrat_regular;
              margin-bottom: 1rem;
              line-height: 1.22;
            }
          }
          .mentor_status_list {
            ul {
              flex-grow: 1;
              li {
                color: $gray-three;
                font-size: $fs16;
                @extend .montserrat_medium;
                font-weight: $fw500;
                &:not(:last-child) {
                  margin: 0 0 4px;
                }
                span {
                  @extend .montserrat_bold;
                  color: $primary-color;
                }
              }
            }
            .more_mentor_status {
              flex-shrink: 0;
              a {
                min-width: 45px;
                padding: 7px;
                font-size: $fs12;
                @extend .montserrat_medium;
                color: $btn_primary;
                border: 1px solid $thub_border;
                background: $hr-color;
                border-radius: 70px;
                @extend .d_block;
                @extend .text_center;
                span {
                  margin-left: 1px;
                }
              }
            }
          }
        }
        .schedual_txt {
          font-size: $fs18;
          color: $tab-head;
          @extend .montserrat_semibold;
          background-color: $light_blue;
          padding: 0.5rem 1.5rem;
          border-radius: 0.8rem;
          margin-bottom: 2.4rem;
          @extend .d_inline_block;
          &.yellow_txt {
            background-color: $light_yellow;
          }
          &.red_txt {
            background-color: rgba(221, 32, 37, 0.1);
            color: $light_red;
          }
        }
        h6 {
          font-size: $fs18;
          color: $black-color;
          margin-bottom: 0.5rem;
          @extend .text_capitalize;
          word-break: break-word;
        }
        p {
          color: $btn_primary;
          font-size: $fs16;
          font-weight: $fw600;
          margin-top: 0.5rem;
        }
        .event_location {
          @extend .d_flex;
          @extend .align_items_center;
          margin-top: 1rem;
          padding: 1rem 1.7rem 1.5rem 1.7rem;
          span {
            color: $gray-three;
            font-size: $fs16;
            @extend .montserrat_regular;
          }
          .option_menu {
            display: grid;
            place-items: center;
            width: 4rem;
            height: 4rem;
            @extend .radius-50;
            &.active {
              border: 2px solid $blue-color;
            }
          }
          .dot_menu_modal {
            box-shadow: rgba(115, 115, 115, 0.15) 0px 0px 30px 0px;
            min-width: 20.9rem;
            padding-bottom: 0px;
            @extend .position_absolute;
            right: 0.8rem;
            width: auto;
            z-index: 99;
            background: $white-color;
            border-width: 0.1rem;
            border-style: solid;
            border-color: $btn_disable;
            border-image: initial;
            border-radius: 0.4rem;
            .list_dot_menu {
              @extend .d_flex_align;
              justify-content: flex-start;
              padding: 1.6rem;
              @extend .montserrat_medium;
              font-size: $fs16;
              color: $gray-color;
              @include media_query(extra_small) {
                padding: 1.2rem;
                font-size: $fs14;
              }
              &:hover {
                background-color: $form-input-active-bg;
              }
              img {
                width: 1.6rem;
              }
            }
          }
          .event_locate_box {
            // margin-top: 1.7rem;
          }
        }
        .eventscard_tooltip {
          span.reason {
            @extend .over_hidden;
            @extend .w_100;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
      .thumbnail_img {
        height: 18rem;
        @extend .w_100;
        object-fit: cover;
        object-position: top;
        border-top-left-radius: 0.8rem;
        border-top-right-radius: 0.8rem;
      }
      .event_location_btn {
        padding: 0.8rem 1.6rem 1.6rem 1.6rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.8rem;
        left: 0;
        right: 0;
        bottom: 1.6rem;
        @extend .w_100;
        button {
          min-width: auto;
          font-size: 1.2rem;
          justify-content: center;
          &.disable {
            background-color: $btn_disable !important;
            -webkit-user-select: none !important;
            user-select: none !important;
            cursor: not-allowed !important;
            border-color: transparent !important;
            color: $black-color !important;
            &:hover {
              color: $black-color;
              &:after {
                opacity: 0;
              }
            }
          }
          &.border_btn {
            border-color: $light_red;
            color: $black-color;
            background: transparent;
            &::after {
              background-color: $light_red;
            }
          }
          &.exp {
            width: 18rem;
            @include media_query(one_extra_large) {
              width: 14rem;
            }
            // @include media_query(medium) {
            //   width: 25rem;
            // }
            @include media_query(small) {
              width: 18rem;
            }
            &.btn_primary {
              // background-color: rgba(19, 115, 231, 0.1);
              background-color: transparent;
              color: $btn_primary;
              white-space: nowrap;
              &:hover {
                &.btn {
                  &:after {
                    opacity: 0;
                  }
                }
              }
            }
            &.border_btn {
              // background-color: rgba(221, 32, 37, 0.1);
              background-color: transparent;
              color: $light_red;
              border-color: rgba(221, 32, 37, 0);
              white-space: nowrap;
              &:hover {
                // color: $white-color;
                &.btn {
                  &:after {
                    opacity: 0;
                  }
                }
              }
            }
          }
          &:hover {
            color: $white-color;
          }
        }
      }
    }
  }
}
// event detail
.event_details_info {
  margin-top: 5rem;
  @include media_query(small) {
    margin-top: 7rem;
  }
  .container {
    max-width: 100%;
    padding-left: 14.1rem;
    @include media_query(one_extra_large) {
      padding-inline: 10rem;
    }
    @include media_query(two_extra_large) {
      padding-inline: 5rem;
    }
    @include media_query(extra_large) {
      padding-inline: 4rem;
    }
    @include media_query(large) {
      padding-inline: 2.5rem;
    }
    @include media_query(extra_small) {
      padding-inline: 2rem;
    }
  }
  @extend .position_relative;
  .top_title {
    margin-bottom: 2.4rem;
    p {
      padding-top: 0.8rem;
    }
  }
  .contact_info {
    padding-bottom: 10rem;
    .name_filed {
      max-width: 32rem;
      @extend .w_100;
      margin-bottom: 2.4rem;
      @include media_query(large) {
        max-width: 100%;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    .geosuggest__suggests-wrapper {
      background: $white-color;
      border-radius: 0;
      border-radius: initial;
      box-shadow: 0 0 30px 0 hsla(0, 0%, 45%, 0.15);
      max-height: 12.5rem;
      overflow: auto;
      z-index: 99;
      max-height: 20rem;
      top: 8rem;
      @extend .position_absolute;
      @extend .custom-scroll;
      @extend .w_100;
      border-radius: 0.4rem;
      ul {
        padding-left: 0;
        .geosuggest__item {
          padding: 1rem 1.2rem 1rem 2rem;
          font-size: $fs14;
          color: $black-two;
          @extend .montserrat_medium;
          @extend .cursor_pointer;
          &:hover {
            background-color: rgba(231, 241, 253, 0.5);
          }
        }
      }
    }
    label {
      color: $black-two;
      &.f_label {
        margin-bottom: 0.8rem;
      }
    }
    .mini_date {
      width: 48%;
      @include media_query(large) {
        width: 100%;
      }
    }
    .upload_form {
      label {
        margin-bottom: 2.4rem;
      }
      .upload_box {
        background: rgba(231, 241, 253, 0.5);
        border-radius: 0.8rem;
        padding: 1.6rem 2.4rem;
        gap: 2.4rem;
        @extend .position_relative;
        @include media_query(large) {
          padding: 1.6rem 2rem;
          gap: 2rem;
        }
        @include media_query(medium) {
          flex-direction: column;
          justify-content: center;
          padding-inline: 2.4rem;
          padding-block: 3.2rem;
          gap: 3.2rem;
        }
        >img,
        .thumb_img {
          width: 8.8rem;
          height: 8.8rem;
          object-fit: none;
          // object-fit: cover;
          &.upload_event_thumbnail {
            width: 8.8rem;
            height: 8.8rem;
            object-fit: cover;
            object-position: center;
          }
          @extend .radius-50;
        }
        .thumb_img {
          border: 2px solid $thub_border;
          border-radius: 0.8rem;
          display: grid;
          place-items: center;
          background-color: $hr-color;
          overflow: hidden;
          @extend .d_block;
          img {
            width: 2.4rem;
            height: 2.4rem;
            border-radius: unset;
          }
          &.thumb_remove {
            border: 0;
            img {
              width: 8.8rem;
              height: 8.8rem;
              border-radius: 0.8rem;
              @extend .img_object_center;
            }
          }
        }
        p {
          color: $black-color;
          font-size: $fs14;
          max-width: 25.5rem;
          @extend .montserrat_bold;
          word-break: break-word;
          @extend .montserrat_bold;
          @include media_query(medium) {
            text-align: center;
          }
          span:not(.not_bold){
            margin-right: 4px;
          }
          span.not_bold {
            @extend .montserrat_regular;
          }
        }
        input[type="file"] {
          font-size: 0;
          width: 0;
          border: 0;
          height: 4rem;
          background: transparent;
          @extend .position_absolute;
          right: 2.4rem;
          opacity: 0;
          @extend .cursor_pointer;
          @include media_query(medium) {
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 3.2rem;
          }
        }
        button {
          padding: 0.6rem 2.4rem;
          font-size: $fs14;
          background-color: $white-color;
          @extend .cursor_pointer;
          margin-left: auto;
          &:hover {
            background-color: $btn_primary;
            color: $white-color;
          }
          span {
            font-size: $fs20;
            line-height: normal;
            margin-left: 1.2rem;
          }
          &.btn_outline {
            max-width: 22.4rem;
          }
          &.btn_upload {
            max-width: 14rem;
            margin-left: auto;
          }
          &.remove_btn {
            padding: 1rem 4.4rem;
            border-radius: 0.8rem;
            background-color: $red-required;
            color: $white-color;
            font-size: $fs14;
            @extend .montserrat_bold;
            @include trans;
            &:hover {
              color: $black-color;
            }
          }
          @include media_query(medium) {
            margin-left: 0 !important;
          }
        }
      }
    }
    .form_group .rc-time-picker + .input_remove {
      padding: 0.5rem;
      margin-top: -0.4rem;
      background-color: #ffffff;
      right: 1.7rem;
      width: auto;
    }
    .input_remove {
      @extend .position_absolute;
      top: 4.4rem;
      right: 1.5rem;
      // width: unset;
      @extend .align_items_center;
      background-color: $white-color;
    }
    .virtual_events {
      @extend .d_flex;
      @extend .align_items_center;
      margin-top: 0.8rem;
      margin-bottom: 0;
      input[type="checkbox"] {
        width: 2.4rem;
        height: 2.4rem;
        opacity: 0;
      }
      span {
        font-size: $fs16;
        @extend .montserrat_regular;
        margin-left: 0.8rem;
        color: $gray-two;
      }
      .checked {
        & + span::before {
          width: 2.4rem;
          height: 2.4rem;
          margin-left: -3rem;
          margin-top: 0;
          border-width: 0.2rem;
        }
        @media (hover: hover) {
          &:hover + span::before {
            background-color: $btn_primary;
            border-color: $btn_primary;
          }
        }
        &:checked + span:before {
          background-color: $btn_primary;
          border-color: $btn_primary;
        }
        & + span::after {
          top: 0rem;
          left: -3.5rem;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 0.5rem;
          height: 1.2rem;
        }
      }
    }
    input.form_control,
    textarea {
      border-color: $popup_border;
      min-height: 4.8rem;
      &::placeholder {
        color: $form-input-color;
      }
    }
    textarea {
      min-height: 13.3rem;
    }
    .fake-placeholder {
      top: 3.8rem;
    }
    #meet_link {
      background-position: right 1.6rem center;
      background-size: 2.4rem;
      background-repeat: no-repeat;
      color: $btn_primary;
    }
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    @extend .d_block;
    background: url(../images/Canedar.svg) no-repeat;
    width: 2.4rem;
    height: 2.4rem;
    margin-top: 0.5rem;
  }
  input[type="time"]::-webkit-calendar-picker-indicator {
    @extend .d_block;
    background: url(../images/time_clock.svg) no-repeat;
    width: 2.4rem;
    height: 2.4rem;
    margin-top: 0.5rem;
  }
  .add_btn {
    @extend .position_relative;
    button {
      @extend .d_block;
    }
    .btn_primary {
      padding: 0.8rem 1.6rem;
      max-width: 13.6rem;
      font-size: $fs14;
    }
    .guest_list {
      @extend .d_flex;
      @extend .flex_wrap;
      @extend .w_100;
      padding-left: 0;
      max-height: 6rem;
      overflow-y: hidden;
      @include media_query(small) {
        max-height: 11rem;
      }
      li {
        @extend .list_unstyled;
        @extend .position_relative;
        @extend .cursor_pointer;
        @extend .d_flex;
        @extend .align_items_center;
        background-color: $grey-color;
        border-radius: 3rem;
        margin-right: 0.8rem;
        margin-bottom: 1.4rem;
        padding: 0.8rem 1.6rem;
        .guest_list_imgs {
          img {
            width: 2.2rem;
            height: 2.2rem;
            object-fit: cover;
            border-radius: 5rem;
          }
        }
        span {
          font-size: $fs16;
          @extend .montserrat_medium;
          color: $black-color;
          padding: 0 0.8rem;
          @extend .text_capitalize;
        }
        &.m_guest_list {
          background-color: $white-color;
          border: 0.1rem solid $btn-disable;
        }
      }
      .footer_text {
        a {
          @extend .montserrat_bold;
          color: $btn_primary;
        }
      }
    }
    .event_view_more_btns {
      a {
        color: $btn_primary;
        font-size: $fs14;
        @extend .montserrat_semibold;
        text-decoration: underline;
      }
    }
    .link_btn {
      font-size: $fs14;
      @extend .montserrat_bold;
      @extend.d_block;
      margin: 0 0 1.4rem 0;
    }
  }
  .sign_footer {
    @include media_query(small) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background: $white-color;
      z-index: 51;
    }
  }
  .sign_text {
    border-top: 0.1rem solid $btn_disable;
    @extend .d_flex_center;
    gap: 17.6rem;
    padding: 2.5rem 2.5rem 2.5rem 0;
    background-color: $white-color;
    @extend .position_relative;
    .footer_text {
      @extend .position_absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    @include media_query(one_extra_large) {
      gap: 15rem;
    }
    @include media_query(two_extra_large) {
      justify-content: flex-end;
    }
    @include media_query(large) {
      gap: 1rem;
      padding: 2.5rem 2rem 2.5rem 0;
    }
    @include media_query(medium) {
      flex-direction: column;
      justify-content: center;
      padding-right: 0;
    }
    .footer_text {
      @include media_query(one_extra_large) {
        padding-left: 5rem;
      }
      @include media_query(large) {
        padding-left: 2rem;
      }
      @include media_query(medium) {
        padding-left: 0;
        position: unset;
        transform: unset;
      }
      a {
        @extend .montserrat_bold;
      }
    }
    .btn_group {
      @extend .d_flex;
      gap: 0.6rem;
      button {
        min-width: 17.6rem;
        font-size: 1.3rem;
        padding: 1rem 3rem;
        &.btn_medium {
          span {
            @extend .mr_10;
          }
        }
        &.btn_primary {
          &:hover {
            svg {
              path {
                fill: $white-color;
                stroke: $white-color;
              }
            }
          }
        }
        &.btn_outline {
          &:hover {
            color: $white-color;
          }
        }
      }
      @include media_query(extra_small) {
        // flex-direction: column;
        justify-content: center;
      }
    }
  }
  @include media_query(small) {
    padding-bottom: 10rem;
  }
}
// event schedual popup
.event_schedual_popup {
  .event_schedual_box {
    border: 0.1rem solid $btn_disable;
    box-shadow: 0px 4px 40px 0px $box_shadow;
    @extend .w_100;
    max-width: 70rem;
    margin: auto;
    padding: 3.2rem;
    border-radius: 2rem;
    .event_title {
      @extend .d_flex;
      @extend .justify_content_between;
      @extend .align_items_center;
      margin-bottom: 3.2rem;
      span {
        @extend .cursor_pointer;
        img {
          width: 4rem;
          height: 4rem;
        }
      }
    }
    .event_meet_list {
      @extend .text_center;
      h4 {
        margin: 3.2rem 0;
      }
      p {
        font-size: $fs20;
        margin-bottom: 3.2rem;
        color: $gray-two;
      }
      a {
        text-decoration: underline;
        font-size: $fs16;
        @extend .montserrat_regular;
      }
    }
    .event_meet_list {
      .meet_icon {
        @extend .w_100;
        max-width: 6.5rem;
        background-color: $form-input-active-bg;
        padding: 1.7rem 1.4rem;
        border-radius: 5rem;
      }
      .meet_txt {
        margin-left: 1.4rem;
        color: $black-color;
        font-size: $fs16;
        @extend .montserrat_medium;
        padding: 0.2rem 3rem;
      }
    }
    .schedual_btn {
      @extend .d_flex;
      @extend .justify_content_center;
      margin-top: 2.4rem;
      .btn_outline {
        padding: 1.2rem 2.4rem;
        max-width: 20rem;
        margin-right: 0.8rem;
      }
      .btn_primary {
        padding: 1.2rem 2.4rem;
        max-width: 20rem;
        @extend .d_flex_center;
        span {
          padding-left: 1.4rem;
        }
      }
    }
    .popup_guest_list {
      margin-bottom: 3.2rem;
      ul {
        @extend .d_flex;
        @extend .justify_content_center;
        li {
          @extend .list_unstyled;
          padding: 0 0.5rem;
        }
      }
    }
  }
}
// notification popup
.event_notification_popup {
  .notification_box {
    @extend .w_100;
    max-width: 104.9rem;
    @extend .custom-scroll;
    margin: auto;
    box-shadow: 0 4px 6px 0 rgba(36, 73, 137, 0.08);
    -webkit-box-shadow: 0 4px 6px 0 rgba(36, 73, 137, 0.08);
    padding: 2.5rem 3.2rem;
    border-radius: 1.6rem;
    @extend .position_fixed;
    top: 50%;
    z-index: 99;
    background-color: $white-color;
    left: 0;
    right: 0;
    min-height: 65.1rem;
    overflow-y: hidden;
    transform: translateY(-50%);

    @include media_query(two_extra_large) {
      max-width: 90%;
    }
    @include media_query(small) {
      max-width: 100%;
      border-radius: 0;
      min-height: 100%;
      padding-inline: 3rem;
      padding-top: 8rem;
    }
    @include media_query(extra_small) {
      padding-inline: 2.5rem;
    }
    .head_noti {
      @include media_query(small) {
        justify-content: center;
        position: relative;
        h6 {
          padding-top: 2rem;
        }
        .cls_btn {
          position: absolute;
          right: 3rem;
          top: 1rem;
        }
      }
    }
    .detail_img {
      @extend .w_100;
      max-width: 100%;
      margin-top: 1.7rem;
      img {
        max-height: 26.7rem;
        border-radius: 0.8rem;
        @extend .w_100;
        @extend .img_object_center;
      }
    }
    .acc_dec_btn {
      @include media_query(medium) {
        flex-wrap: wrap;
        margin-bottom: 1.6rem;
      }
    }
    .event_location_btn {
      gap: 0.8rem;
      @include media_query(small) {
        width: 100%;
      }
      button {
        min-width: auto;
        font-size: $fs12;
        justify-content: center;
        width: 18rem;
        @include media_query(small) {
          width: 50%;
        }
        &.disable {
          background-color: $btn_disable !important;
          -webkit-user-select: none !important;
          user-select: none !important;
          cursor: not-allowed !important;
          border-color: transparent !important;
          color: $black-color !important;
          &:hover {
            color: $black-color !important;
            &:after {
              opacity: 0;
            }
          }
        }
        &.border_btn {
          background: transparent;
          border-color: $light_red;
          color: $black_color;
          white-space: nowrap;
          &::after {
            background-color: $light_red;
          }
          &:hover {
            color: $white-color;
          }
        }
        &.min {
          &.btn_primary {
            &:hover {
              color: $white-color;
            }
          }
        }
        &.exp {
          &.border_btn {
            background-color: transparent;
            border-color: rgba(221, 32, 37, 0);
            color: $light_red;
            &:hover {
              &.btn {
                &:after {
                  opacity: 0;
                }
              }
            }
          }
          &.btn_primary {
            background-color: transparent;
            color: $btn_primary;
            &:hover {
              &.btn {
                &:after {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
    }
    .title_desc {
      @extend .w_100;
      max-width: 24.4rem;
      margin-bottom: 1.6rem;
      align-items: flex-end;
      justify-content: space-between;

      .name_toolkit {
        position: relative;

        &:hover {
          .full_username {
            display: flex;
            align-items: center;
          }
        }
        .status_tab {
          background: #f4f8fd;
          margin-left: 8px;
          line-height: normal;
          color: #222222;
          padding: 0.5rem 1.2rem;
          min-height: 3rem;
          vertical-align: middle;
          display: inline-block;
          border-radius: 0.6rem;
          font-size: 1.5rem;
          font-weight: bold;
        }
        .full_username {
          left: unset !important;
          right: 50% !important;
          transform: translate(50%, 0px);
          line-height: 140%;
          padding: 0.8rem 1rem;
          background: #121b3d;
          color: #ffffff;
          gap: 1rem;
          top: -3.7rem;
          left: 0;
          right: auto;
          z-index: 9;
          display: none;
          border-radius: 0.4rem;
          white-space: nowrap;
          cursor: pointer;
          position: absolute;
          font-size: 1.4rem;
          font-family: "Montserrat_regular";
          font-weight: 400;
          z-index: 9999 !important;
        }
      }
      .event_text {
        padding-top: 2.5rem;
      }
      h6 {
        margin-bottom: 0.5rem;
        font-size: $fs18;
        color: $black-color;
        @extend .text_capitalize;
      }
      p {
        color: $btn_primary;
        font-size: $fs16;
        font-weight: $fw600;
        margin-top: 0.5rem;
      }
    }
    .notification_wrap {
      max-height: 36rem;
      @extend .custom-scroll;
      scrollbar-width: thin;
      scrollbar-color: $scroll-color $form-input-active-bg;
      @include media_query(small) {
        // max-height: 40rem;
        overflow-y: auto;
      }
      .date_time_box {
        border: 0.1rem solid $btn_disable;
        box-shadow: 0px 4px 5px 0px rgba(36, 73, 137, 0.08);
        border-radius: 0.8rem;
        margin-bottom: 1.6rem;
        padding: 2.5rem;
        h6 {
          @extend .montserrat_medium;
        }
        .description_pop {
          color: $btn_primary;
          @extend .montserrat_regular;
          font-style: italic;
          font-weight: $fw500;
        }
        &.mentor_rights_box {
          padding: 0;
          padding-bottom: 1.8rem;
        }
        &.guest_time,
        &.mentor_rights_box {
          padding-inline: 0;
          margin-bottom: 0;
          h6 {
            padding-inline: 2.5rem;
            // margin-bottom: 1.6rem;
          }
          ul {
            li {
              padding: 1.8rem 2.5rem;
              border-bottom: 1px solid $btn-disable;
              .guest_pop_img {
                img {
                  width: 3.2rem;
                  height: 3.2rem;
                  object-fit: cover;
                  @extend .radius-50;
                }
                span {
                  padding-inline: 2.2rem;
                  font-size: $fs16;
                  @extend .montserrat_medium;
                  color: $tab-text;
                }
              }
              .guest_status {
                button {
                  font-size: $fs12;
                  @extend .montserrat_medium;
                  color: $black-color;
                  padding: 0.8rem 1.2rem;
                  border-radius: 3rem;
                  background-color: rgba(116, 116, 116, 0.15);
                  min-width: 97px;
                  &.decline {
                    background-color: rgba(234, 51, 35, 0.2);
                  }
                  &.accept {
                    background-color: rgba(165, 255, 165, 0.6);
                  }
                }
              }
              &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
              }
            }
          }
        }
        .date_time_list {
          @extend .d_flex;
          margin-top: 1rem;
          @extend .d_flex;
          gap: 1.5rem;
          @include media_query(small) {
            max-width: 100%;
            gap: 1rem;
          }
          @media screen and (max-width: 429px) {
            gap: 0.5rem;
          }
          li {
            @extend .list_unstyled;
            @extend .d_flex;
            @extend .align_items_center;
            padding-right: 0.8rem;
            @extend .position_relative;
            img {
              @extend .d_inline_block;
            }
            span {
              font-size: $fs14;
              color: $gray-three;
              padding: 0 0.8rem;
              @extend .montserrat_medium;
            }
            &::after {
              @extend .position_absolute;
              content: "";
              width: 0.2rem;
              height: 1.6rem;
              background-color: $black-color;
              right: 0rem;
              top: 50%;
              transform: translateY(-50%);
            }
            &:last-child {
              &::after {
                @extend .d_none;
              }
            }
            @include media_query(small) {
              padding-right: 0;
              span {
                font-size: $fs12;
                padding: 0 0.5rem;
              }
              &::after {
                height: 1.2rem;
                right: -0.3rem;
              }
            }
            @media screen and (max-width: 429px) {
              padding-right: 0.8rem;
              span {
                font-size: 1.1rem;
              }
              &::after {
                right: 0.2rem;
              }
            }
            @include media_query(extra_small) {
              padding-right: 0;
              &::after {
                right: 0rem;
              }
            }
          }
        }
        .adorama_txt {
          @extend .d_flex;
          @extend .align_items_center;
          @extend .montserrat_medium;
          margin-top: 1.5rem;
          span {
            font-size: $fs14;
            color: $gray-three;
            padding-left: 1.6rem;
          }
          img {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        &.online_box {
          .meet_link_details {
            margin-top: 1.5rem;
            &.meet_links {
              color: $btn_primary;
            }
          }
          .meet_icon {
            @extend .w_100;
            max-width: 6.5rem;
            background-color: $form-input-active-bg;
            padding: 1.7rem 1.4rem;
            border-radius: 5rem;
          }
          .online_box_meet_link {
            padding-left: 1.6rem;
            font-size: $fs16;
            @extend .montserrat_regular;
          }
        }
        @include media_query(small) {
          padding: 2rem;
        }
        @media screen and (max-width: 429px) {
          padding: 1rem;
        }
        &.mentor_rights_box {
          margin-bottom: 1.6rem;
          ul li .guest_pop_img {
            font-size: 1.6rem;
            color: #4f4f4f;
            span {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
}
// event model popup
.event_model_popup_center {
  .event_model_popup {
    width: 62.4rem;
    box-shadow: 0 0 3rem 0 rgba(116, 116, 116, 0.15);
    border-radius: 2rem;
    margin: auto;
    padding: 3.2rem 0;
    background-color: $white-color;
    @include media_query(medium) {
      width: 85%;
    }
    .select_mantes {
      @extend .position_relative;
      margin-bottom: 1.5rem;
      @extend .d_flex_center;
      flex-direction: column;
      @include media_query(small) {
        padding-inline: 1rem;
      }
      h6 {
        @extend .text_center;
        @extend .montserrat_bold;
      }
      p {
        @extend .text_center;
        @extend .montserrat_regular;
        font-size: $fs18;
        color: $black-color;
        padding-top: 3rem;
      }
      .event_popup_close {
        @extend .position_absolute;
        right: 2.4rem;
        @extend .cursor_pointer;
        top: -0.8rem;
      }
    }
    .custom_searchbox {
      padding-top: 1.7rem;
      padding-bottom: 1.6rem;
      padding-inline: 2.4rem;
      form {
        max-width: 100%;
        margin: 0;
      }
    }
    .mentees_check_list {
      padding-top: 1.6rem;
      .select_all {
        padding: 0rem 2.2rem 2rem;
        border-bottom: 1px solid $btn-disable;
        @include media_query(extra_small) {
          padding-inline: 1.5rem;
        }
        label {
          font-size: $fs16;
          color: $gray-color;
          @extend .montserrat_medium;
        }
      }
      .custom-checkbox {
        padding-left: 0;
        .checked {
          & + span::before {
            margin-left: 0rem;
            border-width: 0.2rem;
            margin-top: -0.1rem;
            width: 2.4rem;
            height: 2.4rem;
            background-color: transparent;
            border-color: $gray-two;
          }
          &:checked + span:before {
            background-color: $btn_primary;
            border-color: $btn_primary;
          }
          & + span::after {
            top: -0.1rem;
            left: 0rem;
            width: 0.6rem;
            height: 1.5rem;
          }
        }
        input {
          @extend .d_none;
        }
        span {
          margin-right: 3rem;
          width: 2.4rem;
          height: 2.4rem;
          @include media_query(medium) {
            margin-right: 2rem;
          }
        }
      }
      .empty_message_loader {
        height: 36rem;
        @extend .d_flex_center;
      }
      .empty_message {
        height: 36rem;
        @extend .d_flex_center;
      }
      ul {
        height: 36rem;
        @extend .custom-scroll;
        overflow: auto;
        scrollbar-color: $scroll-color $form-input-active-bg;
        scrollbar-width: thin;
        padding-left: 0;
        li {
          padding: 2rem 2.2rem;
          @extend .list_unstyled;
          border-bottom: 1px solid $btn-disable;
          @extend .d_flex;
          @extend .align_items_center;
          @extend .position_relative;
          margin: 0;
          input {
            margin: 0rem;
            @extend .d_none;
          }
          label {
            font-size: $fs16;
            color: $gray-two;
            padding-left: 1.2rem;
            @extend .montserrat_medium;
            &.lable_text {
              @include media_query(medium) {
                width: calc(100% - 7rem);
              }
            }
            @include media_query(extra_small) {
              font-size: $fs14;
            }
          }
          span {
            width: 3.2rem;
            height: 3.2rem;
            @extend .d_inline_block;
            img {
              border-radius: 5rem;
              @extend .w_100;
              @extend .h_100;
              object-fit: cover;
            }
          }
          &:last-child {
            border: none;
          }
          @include media_query(extra_small) {
            padding-inline: 1.5rem;
          }
          .left_status {
            .legal_info {
              @include media_query(small) {
                width: calc(100% - 8rem);
              }
              @include media_query(extra_small) {
                font-size: 1.3rem;
              }
            }
          }
          .right_info {
            label {
              font-size: $fs12;
              @extend .montserrat_bold;
              color: $primary-color;
            }
            &.right_info_big {
              label {
                padding-right: 2.2rem;
                @include media_query(extra_small) {
                  padding-right: 1rem;
                }
              }
              .check_remove {
                width: 2.4rem;
                height: 2.4rem;
                img {
                  border-radius: unset;
                }
              }
            }
          }
        }
        &.nodatas {
          @extend .d_flex_center;
          p {
            font-size: $fs20;
            @extend .montserrat_bold;
            color: $black-color;
          }
        }
      }
    }
    .cancel_done_btns {
      @extend .d_flex;
      @extend .justify_content_center;
      gap: 3.2rem;
      padding-top: 3.2rem;
      @include media_query(small) {
        padding-inline: 2.4rem;
      }
      .btn_outline {
        border: transparent;
        max-width: 17.5rem;
        padding: 1.2rem 2.4rem;
        color: $btn_primary;
        &:hover {
          background: transparent;
        }
      }
      .btn_primary {
        max-width: 17.5rem;
        padding: 1.2rem 2.4rem;
      }
    }
    &.event_scheduled_popup {
      .scheduled_box {
        .select_mantes {
          @extend .d_flex_align;
          @extend .justify_content_between;
          flex-direction: row;
          margin-bottom: 3.2rem;
          padding-left: 3.2rem;
          h6 {
            @extend .text_left;
            @include media_query(small) {
              text-align: center;
            }
          }
        }
        h2 {
          @extend .text_center;
          font-size: $fs32;
          line-height: 2.8rem;
          color: $black-color;
          @extend .montserrat_semibold;
          padding: 2.8rem 0;
          margin-bottom: 0;
          border-bottom: 1px solid $btn-disable;
          @include media_query(small) {
            line-height: 3.5rem;
            word-break: break-word;
            font-size: 3rem;
          }
        }
        .meet_info {
          padding: 3.2rem;
          @extend .text_center;
          .meet_text {
            h6 {
              font-size: $fs24;
              margin-bottom: 3.2rem;
              line-height: 2.8rem;
            }
            p {
              margin-bottom: 3.2rem;
              color: $gray-two;
              @extend .montserrat_medium;
              font-size: $fs20;
              line-height: 2.8rem;
              margin-left: 0;
              &.link_btn {
                color: $btn_primary;
                @extend .d_inline_block;
                font-size: $fs16;
                @extend .montserrat_regular;
              }
              &.time_d {
                @include media_query(small) {
                  max-width: 25rem;
                  margin: 0 auto 3.2rem;
                }
              }
            }
          }
          .guest_box {
            h2 {
              padding: 0;
              border: 0;
              margin-bottom: 3.2rem;
            }
            .guest_img {
              @extend .d_flex_align;
              @extend .justify_content_center;
              &.guest_single {
                border: 1px solid $btn-disable;
                @extend .radius-50;
                padding: 1.2rem 1.6rem;
                @extend .d_inline_flex;
                .guest_list_imgs {
                  @extend .d_inline_block;
                  img {
                    width: 2.2rem;
                    height: 2.2rem;
                    @extend .radius-50;
                    object-fit: cover;
                    margin-right: 0.8rem;
                  }
                }
                span {
                  font-size: $fs16;
                  color: $black-color;
                  @extend .text_capitalize;
                  @extend .montserrat_medium;
                }
              }
              &.guest_multi {
                .guest_list_imgs {
                  @extend .d_flex_align;
                  @extend .justify_content_center;
                  img {
                    width: 4rem;
                    height: 4rem;
                    object-fit: cover;
                    @extend .radius-50;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
                    margin-left: -0.8rem;
                    max-width: initial;
                  }
                  span {
                    font-size: $fs18;
                    color: $btn_primary;
                    @extend .text_capitalize;
                    @extend .montserrat_semibold;
                    padding: 0.6rem 1.2rem;
                    border: 1px solid $btn-disable;
                    border-radius: 2rem;
                    background-color: $white-color;
                    margin-left: -0.8rem;
                  }
                }
              }
            }
          }
        }
      }
      .cancel_done_btns {
        border-top: 1px solid $btn-disable;
        .btn_primary {
          max-width: 22rem;
          @extend .d_flex_align;
          @extend .justify_content_center;
          svg {
            @extend .d_inline_block;
            margin-right: 1.2rem;
            @include trans;
            path {
              stroke: $white-color;
            }
          }
          &:hover {
            svg {
              path {
                stroke: $white-color;
              }
            }
          }
        }
      }
      @include media_query(extra_small) {
        padding: 3.2rem 2rem;
      }
    }
  }
}
// alert popup
.alert_model_popup {
  .events_model_popup_detail {
    width: 47.5rem;
    background-color: $white-color;
    box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
    margin: auto;
    @extend .text_center;
    @extend .position_absolute;
    padding: 3.2rem;
    border-radius: 2rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h6 {
      font-size: $fs18;
    }
    @include media_query(small) {
      width: 85%;
    }
    @include media_query(extra_small) {
      padding-inline: 1.2rem;
    }
    .event_popup_close_btn {
      @extend .cursor_pointer;
      @extend .position_absolute;
      right: 3.2rem;
      top: 3rem;
      @include media_query(extra_small) {
        right: 2rem;
      }
    }
    .events_process_txt {
      @extend .montserrat_bold;
      color: $black_color;
      padding-top: 2.4rem;
    }
    .alert_imgs {
      margin: 2.4rem auto;
      img {
        @extend .m_auto;
      }
    }
    p {
      color: $black-color;
    }
    .events_model_popup_btns {
      @extend .d_flex;
      @extend .justify_content_center;
      gap: 3.2rem;
      margin-top: 3.2rem;
      @include media_query(medium) {
        gap: 0;
      }
      .no_btn {
        background: transparent;
        color: $btn_primary;
        @extend .montserrat_bold;
        padding: 1.2rem 2.4rem;
        cursor: pointer;
        font-size: $fs16;
        min-width: 13.9rem;
        @include media_query(extra_small) {
          min-width: 10.9rem;
        }
        &:hover {
          color: $white-color;
        }
      }
      .btn_reject {
        background: $red-required;
        @extend .fs_16;
        color: $white-color;
        @extend .cursor_pointer;
        @extend .montserrat_bold;
        padding: 1.2rem 2.4rem;
        border-radius: 0.8rem;
        border: 0.1rem solid $red-required;
        @include trans;
        min-width: 18rem;
        &:hover {
          color: $white-color;
          background: $white-color;
          border: 0.1rem solid $primary-color;
          @include trans;
        }
      }
    }
  }
}
// past event
.switch {
  gap: 0.8rem;
  @include media_query(small) {
    position: absolute;
    top: 8.4rem;
  }
  .switch_name {
    font-size: $fs14;
    color: $gray-two;
    white-space: nowrap;
    @extend .montserrat_regular;
    &:has(~ .switch_btn :checked) {
      @extend .montserrat_bold;
    }
  }
  .switch_btn {
    width: 4.4rem;
    height: 2.4rem;
    background: $btn_disable;
    border-radius: 3rem;
    @extend .cursor_pointer;
    &:has(> .switch_checkbox:checked) {
      background: $btn_primary !important;
    }
    .switch_checkbox {
      @extend .position_absolute;
      // @extend .w_100;
      // @extend .h_100;
      @extend .cursor_pointer;
      opacity: 0;
      z-index: 1;
      &:checked {
        + span {
          left: 50%;
          transition: all 0.4s ease-in-out;
        }
      }
    }
    span {
      background: $white-color;
      font-size: 0;
      height: 2rem;
      left: 0.2rem;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      transition: all 0.4s ease-in-out;
      width: 2rem;
      display: inline-block;
      border-radius: 100%;
      @extend .position_relative;
    }
  }
}
.event_file {
  padding: 0 1.7rem 1.5rem 1.7rem;
  // @extend .position_absolute;
  // bottom: 0;
  // left: 0;
  // right: 0;
  img {
    width: 4.8rem;
    height: 4.8rem;
    object-fit: cover;
    margin-right: 1.6rem;
    @extend .radius-50;
  }
  p {
    max-width: 20rem;
    word-break: break-word;
    white-space: nowrap;
    width: 18.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      font-size: $fs14;
      @extend .montserrat_bold;
      color: $black-two;
    }
  }
}

.event_details_info {
  .form_group {
    .react-datepicker__tab-loop {
      .react-datepicker-popper {
        width: 31.8rem;
        right: 0 !important;
        margin-left: auto;
        .react-datepicker {
          .react-datepicker__triangle {
            transform: translate(165px, 0px) !important;
          }
          .react-datepicker__month-container {
            .react-datepicker__day-names {
              gap: unset;
              justify-content: space-between;
            }
          }
        }
      }
    }
    // time picker
    .rc-time-picker {
      @extend .d_flex_align;
      .rc-time-picker-input {
        border: 0;
        padding: 0;
        font-size: $fs16;
        @extend .cursor_pointer;
        &:focus-visible {
          outline: 0;
        }
      }

      .rc-time-picker-icon {
        background: url(../images/time_clock.svg) no-repeat;
        width: 2.8rem;
        height: 2.6rem;
        @extend .cursor_pointer;
      }
    }
  }
}
// time picker select
.rc-time-picker-panel {
  .rc-time-picker-panel-inner {
    top: 5rem;
    @extend .position_absolute;
    .rc-time-picker-panel-input-wrap {
      @extend .d_none;
    }
  }
  .rc-time-picker-panel-select {
    scrollbar-color: $scroll-color $form-input-active-bg;
    scrollbar-width: thin;
  }
}

.eventscard_tooltip {
  &:hover {
    .tooltip {
      white-space: normal;
      @extend .d_block;
      opacity: 1;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

.dot_menu_modal {
  width: auto;
  min-width: 20.5rem;
  border-radius: 0.4rem;
  border: 0.1rem solid $border-color;
  box-shadow: 0 0 3rem 0 rgba(116, 116, 116, 0.15);
  right: 5.2rem;
  background: $white-color;
  padding-bottom: 0;
  @extend .cursor_pointer;
  top: calc(100% - 24px);
  .list_dot_menu {
    @extend .d_flex;
    @extend .align_items_center;
    @extend .justify_content_start;
    padding: 1.6rem;
    @extend .fs_14;
    @extend .montserrat_medium;
    img {
      margin-right: 1.6rem;
      width: 1.6rem;
      height: 1.6rem;
      @extend .img_object_center;
    }

    &:hover {
      background: $form-input-active-bg;
    }

    

    &.disable_menu {
      opacity: 0.5;
      cursor: default;
    }
  }
}