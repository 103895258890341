.message_box {
  display: grid;
  grid-template-columns: 356px auto 320px;
  padding-top: 7.5rem;
  height: 100vh;
  @extend .flex_wrap;
  @include media_query(one_extra_large) {
    grid-template-columns: auto 320px;
  }
  @include media_query(large) {
    grid-template-columns: 1fr;
    height: 100%;
  }
  @include media_query(small) {
    background-color: $mobile_bg;
    padding-top: 5.7rem;
  }
  .comman_lay {
    padding: 2.5rem 0;
    border-right: 0.1rem solid $btn_disable;
    @extend .position_relative;
    @include media_query(large) {
      border-right: 0;
    }
    &.content_share {
      padding-top: 0;
    }
    &:last-child {
      border-right: 0;
      @include media_query(one_extra_large) {
        min-width: 30rem;
      }
      &.content_share {
        @include media_query(small) {
          display: block;
          padding-top: 0;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
    .serchbox {
      padding: 0 3.2rem;
      @include media_query(one_extra_large) {
        padding: 0 1.2rem;
      }
      @include media_query(small) {
        padding: 0 2.5rem;
      }
      .serchbox_form {
        @extend .position_relative;
        min-width: 29.2rem;
        @include media_query(three_extra_large) {
          min-width: 100%;
        }
        input {
          background-color: $search-input;
          @extend .w_100;
          height: 5.5rem;
          @extend .radius-50;
          @extend .border_0;
          padding-left: 5.5rem;
          padding-right: 3.2rem;
          @extend .montserrat_medium;
          font-size: $fs16;
          color: $gray-two;
          line-height: 2.2rem;
          &:active,
          &:focus {
            outline: none;
          }
        }
        // input[type="search"]::-webkit-search-cancel-button {
        //   appearance: none;
        //   background: url("../images/serch_list_remove_icon.svg") no-repeat
        //     center;
        //   object-fit: contain;
        //   width: 2.5rem;
        //   height: 2.5rem;
        //   @extend .cursor_pointer;
        // }
        button {
          @extend .border_0;
          background-color: transparent;
          @extend .position_absolute;
          left: 1.9rem;
          top: 50%;
          transform: translateY(-50%);
          &.blue_close {
            right: 2rem;
            left: auto;
            &:hover {
              .tooltip {
                @extend .d_block;
                @extend .opacity_1;
                left: -5rem;
              }
            }
            img {
              height: 2rem;
            }
          }
        }
      }
    }
    h2 {
      font-size: $fs24;
      @extend .montserrat_medium;
      color: $black-color;
      padding: 2.5rem 3.2rem;
      @include media_query(one_extra_large) {
        padding: 2.5rem 1.2rem;
      }
      @include media_query(small) {
        padding: 2.5rem 2.5rem 1rem;
      }
      line-height: normal;
    }
    .chat_listing {
      max-height: 65vh;
      @extend .custom-scroll;
      overflow-y: auto;
      scrollbar-color: $scroll-color $form-input-active-bg;
      scrollbar-width: thin;
      @include media_query(small) {
        max-height: 60vh;
      }
      .chat_info {
        padding: 2rem 3rem;
        align-items: flex-start;
        @include media_query(one_extra_large) {
          padding: 2rem 1.2rem;
        }
        @include media_query(small) {
          padding: 2rem 0rem;
        }
        border-top: 1px solid $btn_disable;
        &:hover,
        &.selected {
          background-color: rgba(214, 226, 245, 0.2);
          @extend .cursor_pointer;
        }
        .chat_profile {
          width: calc(100% - 7rem);
          .chat_name{
            width: calc(100% - 5rem);
          }
          p {
            @extend .montserrat_semibold;
            font-size: $fs16;
            color: $black-color;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            // word-break: break-all;
            text-overflow: ellipsis;
            padding-right: 1rem;
          }
          img {
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
            @extend .radius-50;
            margin-right: 1rem;
            object-fit: cover;
            @include profile_small;
            flex-shrink: 0;
          }
          .chat_name {
            @include media_query(small) {
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              overflow: hidden;
              word-break: break-all;
            }
            span {
              white-space: nowrap;
              @extend .over_hidden;
              text-overflow: ellipsis;
              @extend .d_block;
            }
          }
        }
        span {
          color: $gray-three;
          @extend .montserrat_medium;
          font-size: $fs14;
        }
        .chat_status {
          @extend .text_right;
          span {
            @extend .d_flex;
            &.chat_time {
              font-size: 1.3rem;
              margin-left: 1rem;
              white-space: nowrap;
            }
          }
          .msg_num {
            width: 1.8rem;
            height: 1.8rem;
            @extend .radius-50;
            background-color: $btn_primary;
            @extend .justify_content_center;
            @extend .align_items_center;
            margin-left: auto;
            margin-top: 0.1rem;
            display: inline-table;
            vertical-align: middle;
            @extend .text_center;
            padding: 0 0.2rem;
            line-height: 1.8rem;
            span {
              color: $white-color;
              font-size: 1rem;
              @extend .montserrat_bold;
              display: inline-table;
            }
          }
          img {
            margin-left: auto;
            @extend .text_right;
            @extend .d_block;
            padding-top: 0.5rem;
          }
        }
        &:last-child {
          border-bottom: 0.01rem solid $btn_disable;
          @include media_query(large) {
            border-bottom: 0;
          }
        }
        &:first-child {
          @include media_query(small) {
            border-top: 0;
          }
        }
      }
      @include media_query(small) {
        overflow-x: hidden;
        padding: 0rem 2.7rem 2.5rem;
        height: calc(100vh - 15.9rem - 13rem);
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background: $table-hover;
        }
        &::-webkit-scrollbar-thumb {
          background: $primary-color;
        }
      }

      .nodatabox {
        @extend .position_absolute;
        .nodatainfo {
          @include media_query(two_extra_large) {
            width: 100%;
            padding-inline: 3.5rem;
          }
        }
      }
    }
    &.connection_list {
      @extend .position_relative;
      @include media_query(one_extra_large) {
        &.mobile-hide {
          display: none;
        }
      }
    }
  }
  // no message data style
  &.expand_box {
    .new_msg_set {
      padding: 2.5rem 3.2rem;
      @include media_query(small) {
        padding: 1rem;
      }
      .msg_connection_title {
        @extend .d_flex_align;
        h3 {
          font-size: $fs24;
          @extend .montserrat_medium;
        }
        .new_msg_btn {
          margin-left: auto;
          @include media_query(small) {
            position: absolute;
            bottom: 3.5rem;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
          }

          .message_btns {
            max-width: 19.3rem;
            &:hover {
              color: $white-color;
            }
            .new_msg_btn_title {
              margin-right: 1.2rem;
              white-space: nowrap;
            }
            img {
              filter: brightness(21);
            }
          }
        }
      }
    }
  }
  &.false {
    .new_msg_set {
      padding: 1.5rem 3.2rem;
      .msg_connection_title {
        @extend .d_flex_align;
        h3 {
          font-size: $fs24;
          @extend .montserrat_medium;
        }
      }
      .new_msg_btn {
        @extend .position_absolute;
        bottom: -0.5rem;
        left: 0;
        right: 0;
        background: $white-color;
        padding: 40px 0;
      }
      .message_btns {
        max-width: 19.7rem;
        @extend .position_absolute;
        bottom: 4rem;
        left: 0;
        right: 0;
        margin: auto;
        &:hover {
          color: $white-color;
        }
        .new_msg_btn_title {
          margin-right: 1.2rem;
        }
        img {
          filter: brightness(21);
        }
      }
    }
  }
  .no_message_found {
    @extend .w_100;
    max-width: 53.1rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @extend .position_absolute;
    .no_message_page {
      width: 12rem;
      height: 12rem;
      @extend .radius_round;
      @extend .d_flex_center;
      @extend .m_auto;
      background: $form-input-active-bg;
      margin-bottom: 2.4rem;
    }
    .no_message_content {
      @extend .text_center;
      font-size: $fs16;
      color: $gray-three;
      @extend .montserrat_regular;
      span {
        @extend .montserrat_bold;
        color: $btn_primary;
      }
    }
  }
  .chat_box {
    padding-bottom: 0;
    padding-top: 0;
    @extend .d_flex;
    flex-direction: column;
    max-height: calc(100vh - 75px);
    @include media_query(small) {
      max-height: calc(100vh - 57px);
    }
    .chat_box_info {
      padding: 2.5rem 3.2rem;
      @include media_query(one_extra_large) {
        padding: 2.5rem 1.2rem;
      }
      @include media_query(small) {
        background-color: $mobile_bg;
        padding: 1.9rem 2.5rem;
      }
      border-bottom: 1px solid $btn_disable;
      position: sticky;
      top: 0;
      .chat_box_profile {
        .back_btn {
          margin-right: 1rem;
          @extend .d_none;
          @include media_query(small) {
            display: block;
          }
          span {
            @extend .msg_border_icon;
            width: 3rem;
            height: 3rem;
            img {
              width: 1.8rem;
              height: 1.8rem;
              @extend .m_auto;
            }
          }
        }
        .pro_pic {
          @extend .cursor_pointer;
          width: 5.5rem;
          height: 5.5rem;
          object-fit: cover;
          @extend .radius-50;
          margin-right: 1.2rem;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
          @include media_query(small) {
            width: 4.3rem;
            height: 4.3rem;
          }
        }
        .chat_profile_name {
          @extend .cursor_pointer;
          p {
            @extend .montserrat_semibold;
            color: $black-color;
            font-size: $fs16;
          }
        }
      }
      .chat_close {
        @extend .msg_border_icon;
        @extend .cursor_pointer;
        &:hover {
          background-color: $primary-color;
          img {
            filter: brightness(10);
          }
        }
        @include media_query(small) {
          width: 3rem;
          height: 3rem;
          img {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
    .chatbox_area {
      @extend .custom-scroll;
      overflow-x: hidden;
      padding: 2.5rem 2.7rem;
      overflow-y: auto;
      scrollbar-color: $scroll-color $form-input-active-bg;
      scrollbar-width: thin;
      height: calc(100vh - 286px);
      @include media_query(one_extra_large) {
        padding: 2.5rem 1.2rem;
      }
      @include media_query(small) {
        padding: 1.5rem 2.5rem;
        // height: calc(100vh - 224px);
        height: calc(100vh - 235px);
      }
      @extend .position_relative;
      .suggestion_tab {
        @extend .position_absolute;
        bottom: 2.5rem;
        left: 3.4rem;
        ul {
          @extend .list_unstyled;
          column-gap: 1.2rem;
          row-gap: 1rem;
          @extend .flex_wrap;
          @include media_query(small) {
            column-gap: 0.8rem;
            row-gap: 0.8rem;
          }
          li {
            @extend .montserrat_medium;
            font-size: $fs16;
            color: $gray-two;
            line-height: 2.2rem;
            padding: 1rem 2rem;
            background-color: $search-input;
            @extend .cursor_pointer;
            @extend .radius-50;
            @include trans;
            &:hover {
              background-color: $primary-color;
              color: $white-color;
            }
            @include media_query(small) {
              font-size: 1.3rem;
              padding: 0.8rem 1.6rem;
            }
          }
        }
        @include media_query(small) {
          left: 0;
          padding: 0 2.5rem;
        }
      }
      .chatting {
        @extend .d_flex;
        @extend .flex_direction_column;
        .chat_loading_area {
          background-color: $btn_primary;
          color: $white-color;
          border-radius: 0.8rem;
        }
        .message {
          @extend .position_relative;
          .link_btn{
            font-style: italic;
          }
          p {
            padding: 1.4rem 2.2rem;
            @extend .montserrat_medium;
            font-size: $fs16;
            line-height: 2.2rem;
            border-radius: 0.8rem;
            border: 1px solid $btn_disable;
            background-color: $white-color;
            color: $gray-two;
            span{
              @extend .fs_16;
              word-break: break-word;
              color: $gray-two;
              a{
                  color: $secondary-color;
                  font-style: normal;
                  text-decoration: underline;
              }
            }
          }
          span {
            font-size: $fs12;
            @extend .montserrat_regular;
            line-height: 2.2rem;
            letter-spacing: 0.06px;
            color: $msg-date;
            margin-top: 0.5rem;
            @extend .d_block;
            display: flex;
            align-items: center;
            img {
              margin-left: 0.5rem;
              @extend .position_relative;
              top: 0;
            }
          }
          margin-bottom: 2.5rem;
          clear: both;
          &.my_message {
            max-width: 100%;
            display: inline-flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            margin-left: auto;
            float: right;
            // word-break: break-all;
            p {
              @extend .w_100;
            }
            // @include media_query(one_extra_large) {
            //   max-width: 90%;
            // }
            // @include media_query(large) {
            //   max-width: 50%;
            // }
            // @include media_query(medium) {
            //   max-width: 95%;
            // }
            p{
              background-color: $primary-color;
              color: $white-color;
              border-bottom-right-radius: 0;
              @extend .d_inline_block;
              width: auto;
              span{
                @extend .fs_16;
                word-break: break-word;
                color: $white-color;
                a{
                  color: currentColor;
                  font-style: normal;
                  text-decoration: underline;
                }
              }
              > a {
                @extend .cursor_pointer;
                color: $white-color;
                &:hover {
                  color: $white-color !important;
                }
              }
            }
            &.voice_msg_wrapper {
              max-width: 100%;
              word-break: unset;
            }
            .react_emoji {
              right: unset;
              left: -1.2rem;
            }
          }
          &.other_message {
            max-width: 100%;
            display: inline-flex;
            flex-direction: column;
            &.meeting_msg {
              min-width: 50rem;
              @include media_query(medium) {
                min-width: auto;
              }
            }
            @include media_query(one_extra_large) {
              max-width: 90%;
            }
            @include media_query(large) {
              max-width: 50%;
            }
            @include media_query(medium) {
              max-width: 95%;
            }
            p {
              border-top-left-radius: 0;
              justify-content: flex-start;
              border-top-left-radius: 0;
              width: auto;
              // word-break: break-all;
              a {
                @extend .cursor_pointer;
                &:hover {
                  color: $btn_primary !important;
                }
              }
            }
            align-items: start;
            .welcome_box {
              border-radius: 1.6rem;
              border: 1px solid $btn_disable;
              padding: 1.6rem 2.4rem;
              border-top-left-radius: 0;
              p {
                @extend .border_0;
                background-color: transparent;
              }
              .welcome_img {
                margin-bottom: 1.6rem;
                span {
                  width: 3.25rem;
                  height: 3.25rem;
                  background-color: $primary-color;
                  @extend .radius-50;
                  display: grid;
                  place-items: center;
                  img {
                    margin-left: 0;
                  }
                  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
                }
                strong {
                  font-size: 1rem;
                  margin-left: 0.6rem;
                  @extend .montserrat_bold;
                }
              }
              .welcome_info {
                h4 {
                  font-size: $fs18;
                  letter-spacing: 0.095px;
                  color: $primary-color;
                  margin-bottom: 3rem;
                }
                p {
                  font-size: $fs14;
                  line-height: normal;
                  color: $form-input-color;
                  @extend .p_0;
                }
              }
            }
            .meeting_wrapper {
              border-radius: 1.6rem;
              border: 1px solid $btn_disable;
              border-top-left-radius: 0;
              p {
                @extend .border_0;
                background-color: transparent;
                @extend .p_0;
                letter-spacing: 0.08rem;
              }
              .meeting_request {
                padding: 1.4rem 2rem 2rem;
                border-bottom: 1px solid $btn_disable;
                .meeting_img {
                  margin-bottom: 1.2rem;
                  img {
                    width: 4.8rem;
                    height: 4.8rem;
                    border-radius: 0.4rem;
                    object-fit: cover;
                    margin-right: 1.6rem;
                  }
                  strong {
                    @extend .montserrat_semibold;
                    font-size: $fs16;
                    line-height: 2.4rem;
                    color: $black-color;
                    word-break: break-word;
                  }
                }
                .d_time {
                  h5 {
                    @extend .montserrat_semibold;
                    font-size: $fs16;
                    color: $tab-head;
                    @extend .text_capitalize;
                    margin-bottom: 1.2rem;
                  }
                  .date_img {
                    img {
                      width: 2.4rem;
                      margin-right: 1.2rem;
                    }
                    ul {
                      display: grid;
                      gap: 1rem;
                      grid-template-columns: 1fr 1fr;
                      li {
                        @extend .montserrat_semibold;
                        font-size: $fs16;
                        line-height: 1;
                        color: $tab-text;
                        border-right: 1px solid $tab-text;
                        padding-right: 1.2rem;
                        &:last-child {
                          @extend .border_0;
                          padding-right: 0;
                        }
                        @include media_query(medium) {
                          font-size: $fs12;
                        }
                      }
                    }
                  }
                }
              }
              .meeting_data {
                padding: 2rem 1.4rem;
                h5 {
                  @extend .montserrat_bold;
                  font-size: $fs16;
                  letter-spacing: 0.08px;
                  color: $black-color;
                  margin-bottom: 1.2rem;
                }
              }
            }
          }
          &.my_file {
            p {
              @extend .border_0;
              @extend .montserrat_medium;
              color: $black-color;
              @extend .d_flex_align;
              padding: 0.5rem;
              background-color: transparent;
              &.file_border {
                border: 1px solid hsla(0, 0%, 80%, 0.545);
                border-radius: 1rem;
                padding: 1rem;
              }
              img {
                margin-right: 1rem;
                width: 3.2rem;
                height: 3.2rem;
              }
            }
          }
          &.voice_msg {
            background-color: $login_bg;
            border-radius: 2.4rem;
            padding: 0.8rem 1.2rem;
            min-width: 32.2rem;
            margin-bottom: 0;
            .volume_box {
              @extend .d_flex;
              @extend .align_items_center;
              gap: 1.1rem;
              p {
                @extend .border_0;
                @extend .p_0;
                @extend .montserrat_regular;
                font-size: $fs14;
                color: $black-color;
                opacity: 0.6;
                height: auto;
                background: transparent;
                white-space: nowrap;
                img {
                  width: 4rem;
                  height: 4rem;
                  object-fit: cover;
                }
              }
              .wave {
                max-width: 17rem;
                @include media_query(extra_small) {
                  max-width: 10rem;
                }
              }
            }
            @include media_query(extra_small) {
              max-width: 28rem;
            }
          }
          .emogi_box {
            @extend .position_absolute;
            bottom: -1.2rem;
            left: 8.7rem;
            @include media_query(extra_small) {
              left: 6rem;
            }
            span {
              padding: 1.4rem 2rem;
              border-radius: 0.8rem;
              border: 1px solid $btn_disable;
              background-color: $white-color;
              border-top-left-radius: 0;
            }
          }
          .media_img_wrapper {
            .media_img {
              // width: 30rem;
              // height: 20rem;
              object-fit: cover;
              max-height: 30rem;
              max-width: 30rem;
              width: auto;
              height: auto;
              border-radius: 1rem;
            }
            &:hover {
              .media_img_icon_section {
                opacity: 1;
              }
            }
            .media_img_icon_section {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              display: flex;
              opacity: 0;
              .media_img_icon {
                width: 36px;
                height: 36px;
                background: rgba(30, 37, 56, 0.5);
                border-radius: 3px;
                display: flex;
                margin: 0 5px;
                cursor: pointer;
                svg {
                  margin: auto;
                }
              }
            }
          }

          .react_emoji {
            width: 3rem;
            height: 3rem;
            border: 1px solid $btn_primary;
            display: grid;
            place-items: center;
            @extend .radius-50;
            @extend .position_absolute;
            bottom: 1.5rem;
            right: -1.2rem;
            background-color: $white-color;
            &.text_message {
              bottom: -1rem;
              right: -1.5rem;
            }
            span {
              margin-top: 0;
              line-height: normal;
              font-size: $fs16;
            }
          }
          &.web_admin_msg {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
        }
        .left_conversation_box {
          @extend .position_absolute;
          bottom: 2.7rem;
          left: 0;
          right: 0;
          @extend .text_center;
          @include media_query(small) {
            padding-inline: 2.5rem;
          }
          @include media_query(extra_small) {
            padding-inline: 2rem;
          }
          p {
            @extend .d_inline_block;
            font-size: $fs16;
            color: $msg-date;
            line-height: 2.2rem;
            @extend .montserrat_semibold;
            border: 1px solid $btn_disable;
            @extend .radius-50;
            padding: 1.6rem 3.2rem;
            box-shadow: 0px 4px 40px 0px rgba(36, 73, 137, 0.08);
            @include media_query(small) {
              padding: 1.5rem 2rem;
              font-size: $fs14;
            }
            @include media_query(extra_small) {
              padding: 1rem 1.2rem;
              font-size: $fs12;
            }
          }
        }
        .voice_msg_box {
          padding: 3.2rem;
          background-color: $search-input;
          @extend .position_absolute;
          @extend .w_100;
          bottom: 0;
          left: 0;
          right: 0;
          @include media_query(small) {
            padding: 2.5rem;
          }
          .voice_text {
            margin-bottom: 2.5rem;
            p {
              @extend .montserrat_semibold;
              font-size: $fs16;
              line-height: 1.9rem;
              color: $black-color;
            }
            span {
              @extend .msg_border_icon;
              background-color: $white-color;
              img {
                width: 2.4rem;
                height: 2.4rem;
              }
            }
          }
          .sound_icon {
            @extend .text_center;
            span {
              @extend .position_relative;
              @extend .d_block;
              z-index: 0;
              &::after {
                content: "";
                @extend .position_absolute;
                width: 8.9rem;
                height: 8.9rem;
                -webkit-animation-delay: 0s;
                animation-delay: 0s;
                -webkit-animation: pulsate1 4s;
                animation: pulsate1 4s;
                -webkit-animation-direction: forwards;
                animation-direction: forwards;
                -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: $btn_primary;
                opacity: 0.3;
                z-index: -1;
              }
              @-webkit-keyframes pulsate1 {
                0% {
                  -webkit-transform: translate(-50%, -50%) scale(0.6);
                  transform: translate(-50%, -50%) scale(0.6);
                  opacity: 1;
                  opacity: 0.3;
                }
                50% {
                  -webkit-transform: translate(-50%, -50%) scale(1);
                  transform: translate(-50%, -50%) scale(1);
                  opacity: 0.3;
                }
                80% {
                  -webkit-transform: translate(-50%, -50%) scale(1.1);
                  transform: translate(-50%, -50%) scale(1.1);
                  opacity: 0.4;
                }
                100% {
                  -webkit-transform: translate(-50%, -50%) scale(1.2);
                  transform: translate(-50%, -50%) scale(1.2);
                  opacity: 0.2;
                }
              }

              @keyframes pulsate1 {
                0% {
                  -webkit-transform: translate(-50%, -50%) scale(0.6);
                  transform: translate(-50%, -50%) scale(0.6);
                  opacity: 0.3;
                }
                50% {
                  -webkit-transform: translate(-50%, -50%) scale(1);
                  transform: translate(-50%, -50%) scale(1);
                  opacity: 0.3;
                }
                80% {
                  -webkit-transform: translate(-50%, -50%) scale(1.1);
                  transform: translate(-50%, -50%) scale(1.1);
                  opacity: 0.4;
                }
                100% {
                  -webkit-transform: translate(-50%, -50%) scale(1.2);
                  transform: translate(-50%, -50%) scale(1.2);
                  opacity: 0.2;
                }
              }
            }
          }
        }
        .web_admin_msg {
          p {
            border-radius: 0px 4px 4px 4px;
            background: $dark_gray;
            padding: 16px;
            font-style: italic;
            @extend .fw_500;
            @extend .fs_16;
            line-height: 140%;
            color: $white-color;
            border: 1px solid #fff;
            @extend .text_center;
          }
          .wb_text_msg {
            @extend .d_flex;
            @extend .align_items_center;
            @extend .justify_content_center;
            margin: 0 0 2.5rem;
          }
          .message.other_message {
            @extend .d_flex;
            @extend .align_items_center;
            @extend .justify_content_center;
            flex-direction: row;
            @extend .flex_wrap;
            gap: 1.5rem;
            .file_border {
              .pdf_size {
                margin: 0 0 0 1rem;
              }
            }
          }
          &.admin_text_msg {
            max-width: 80%;
            width: 100%;
            margin-inline: auto;
            @include media_query(medium) {
              max-width: 95%;
            }
            p{
              span{
                @extend .fs_16;
                word-break: break-word;
                color: $white-color;
                a{
                    color: currentColor;
                    font-style: normal;
                    text-decoration: underline;
                }
              }
            }
            .link_btn{
              color: $white-color;
              &:hover{
                color: $white-color;
              }
            }
          }
          &.wb_doc_img {
            @extend .d_flex;
            @extend .align_items_center;
            @extend .justify_content_center;
            @extend .flex_wrap;
            gap: 1.5rem;
            .wb_img_block {
              max-width: 180px;
              width: 100%;
              border: 1px solid $border-color;
              border-radius: 8px;
              position: relative;
              padding-bottom: 21rem;
              overflow: hidden;
              @include media_query(small) {
                max-width: 100px;
                padding-bottom: 11.5rem;
              }
              img {
                position: absolute;
                left: 0;
                width: 100%;
                top: 0;
                height: 100%;
                object-fit: cover;
              }
            }
            .admin_text_msg{
              max-width: 100%;
            }
          }
        }
      }
    }
    .left_user {
      @extend .m_auto;
      @extend .w_100;
      @extend .d_block;
      @extend .text_center;
      margin-bottom: 2.7rem;
      padding: 1.5rem 1rem 0;
      p {
        @extend .montserrat_semibold;
        font-size: $fs16;
        font-style: normal;
        line-height: 2.24rem;
        color: $msg-date;
        padding: 1.6rem 3.2rem;
        @extend .radius-50;
        border: 1px solid $btn-disable;
        @extend .text_center;
        @extend .d_inline_block;
        @extend .m_auto;
        width: auto;
        background-color: $white-color;
        box-shadow: 0px 4px 40px rgba(36, 73, 137, 0.08);
        @include media_query(small) {
          // white-space: nowrap;
          padding: 1.6rem;
          font-size: $fs14;
        }
      }
    }
    .video_popup {
      .modal_heading {
        opacity: 0;
        transition: all 1s ease-out;
      }
      .mid__container {
        opacity: 0;
        transition: all 1s ease-out;
      }
      .bottom__container {
        opacity: 0;
        transition: all 1s ease-out;
      }
      &:hover {
        .modal_heading {
          opacity: 1;
        }
        .mid__container {
          opacity: 1;
        }
        .bottom__container {
          opacity: 1;
        }
      }
      &.audio_popup {
        .modal_heading {
          opacity: 1;
        }
        .mid__container {
          opacity: 1;
        }
        .bottom__container {
          opacity: 1;
        }
        .progress_bar {
          margin-left: 4rem;
        }
      }
    }
  }
  &.expand_box {
    grid-template-columns: 1fr;
    .comman_lay {
      border-right: 0;
      .chat_listing {
        .chat_info {
          .chat_profile {
            .chat_name {
              span {
                width: 25rem;
              }
            }
          }
        }
      }
    }
    .chat_box,
    .content_share {
      @extend .d_none;
    }
  }
  .content_share {
    @extend .p_0;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    @include media_query(three_extra_large) {
      grid-template-rows: repeat(2, 1fr);
    }
    @include media_query(small) {
      grid-template-rows: repeat(4, 1fr);
    }
    .content_box {
      padding: 0 2.5rem 2.4rem;
      border-bottom: 1px solid $btn_disable;
      @include media_query(one_extra_large) {
        padding: 0 1.2rem 2rem;
      }
      @include media_query(large) {
        padding: 0 2.5rem 2rem;
      }
      @include media_query(small) {
        padding: 2.5rem;
      }
      .empty_box {
        @extend .d_flex_align;
        @extend .w_100;
        @extend .h_100;
        display: grid;
        p {
          @extend .text_center;
          color: $primary-color;
          font-size: $fs16;
          @extend .montserrat_semibold;
          @include media_query(three_extra_large) {
            width: 100%;
          }
        }
      }
      h2 {
        padding-inline: 0;
      }
      .message_tab_scroll {
        max-height: 20rem;
        overflow: auto;
        @include media_query(mac_device) {
          min-height: 26rem;
        }
        @media (max-width: 1920px) {
          min-height: 0;
          max-height: 18rem;
        }
        @include media_query(three_extra_large) {
          max-height: 25rem;
        }
        .list_box {
          margin-bottom: 1.5rem;
          img {
            @extend .radius-50;
          }
          span {
            @extend .msg_border_icon;
            border: none;
            margin-top: 0.2rem;
            img {
              width: 4rem;
              height: 4rem;
            }
          }
          .text_info {
            margin-left: 1rem;
            width: calc(100% - 5rem);
            @include media_query(three_extra_large) {
              width: calc(100% - 6rem);
            }
            p {
              color: $black-color;
              font-size: $fs16;
              @extend .montserrat_medium;
              @extend .text_capitalize;
            }
            a {
              color: $btn_primary;
              line-height: normal;
              border-bottom: 1px solid;
              font-size: $fs14;
              @extend .montserrat_regular;
              @include trans;
              &:hover {
                color: $primary-color;
                @extend .montserrat_semibold;
              }
            }
          }
          &.list_box_project {
            span {
              background-color: $project_bg;
              img {
                width: auto;
                height: auto;
              }
            }
          }
        }
      }
      &:nth-child(2) {
        .share_file {
          @include media_query(small) {
            h2 {
              padding-top: 0;
            }
          }
        }
      }
    }
    .mobile_group {
      @extend .text_center;
      padding-top: 2.5rem;
      @extend .d_none;
      h2 {
        @extend .montserrat_semibold;
      }

      .back_btn {
        @extend .d_flex_align;
        justify-content: flex-start;
        padding: 0 3.4rem 2.4rem 3.4rem;
        img {
          height: 2.4rem;
          width: 2.4rem;
          border: 1px solid $gray-two;
          border-radius: 50%;
          margin-right: 2rem;
          filter: grayscale(1);
          @extend .cursor_pointer;
        }
        span {
          @extend .cursor_pointer;
          @extend .montserrat_semibold;
          font-size: $fs18;
        }
        @include media_query(large) {
          padding-top: 2.4rem;
          position: fixed;
          width: auto;
          left: 17rem;
          right: 0;
          margin: 0 auto;
          z-index: 1;
          background-color: $white-color;
        }
        @include media_query(small) {
          left: 0;
          width: 100%;
          background-color: $mobile_bg;
        }
      }

      @include media_query(large) {
        display: block;
        padding-top: 0;
        .group_img {
          margin-top: 9.6rem;
          img {
            margin: 0 auto;
            width: 12rem;
            height: 12rem;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
      @include media_query(small) {
        .group_img {
          margin-top: 0;
          padding-top: 9.8rem;
        }
      }
    }
  }
  .chat_footer {
    position: unset;
    // @include media_query(small){
    //   position: fixed;
    //   bottom: 0;
    // }
  }
}
.image_full_view {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1001;
  .image_full_view_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    .image_full_view_header {
      display: flex;
      align-items: center;
      padding: 15px 20px;
      position: sticky;
      top: 0;
      .image_full_title {
        width: calc(100% - 30px);
        text-align: center;
        font: normal 500 2.2rem/2.4rem "Mulish", sans-serif;
        color: #fff;
      }
      .image_full_action {
        display: flex;
        align-items: center;
        .u_plain-icon {
          cursor: pointer;
          line-height: 0;
          display: block;
          color: #4e525f;
          position: relative;
        }

        .u_mr-10 {
          margin-right: 10px;
        }
        .close-icon {
          border: 2px solid #fff;
          width: 30px;
          height: 30px;
          display: flex !important;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          cursor: pointer;
        }
        .u_cp {
          cursor: pointer;
        }
        .u_ml-10,
        .u_mx-10 {
          margin-left: 10px;
        }
      }
    }
    .image_full_view-body {
      margin: auto;
      width: calc(100% - 112px);
      height: 100%;
      min-height: 1px;
      text-align: center;
      .image_full_popup {
        text-align: center;
        margin: auto;
        display: inline-flex;
        height: 100%;
        align-items: center;
        .u_image-zoom {
          display: inline-flex;
          overflow: hidden;
          width: 100%;
          height: 100%;
          margin: auto;
          img {
            margin: auto;
            height: auto;
            max-height: 100%;
          }
        }
      }
    }
    .u_thumbnails-file {
      height: 100px;
    }
  }
}
.voice_msg_box {
  padding: 3.2rem;
  background-color: $search-input;
  @extend .position_absolute;
  @extend .w_100;
  bottom: 10.6rem;
  left: 0;
  right: 0;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: fadeInUp 1s;
  animation: fadeInUp 1s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  overflow: hidden;
  z-index: 0;
  @include media_query(small) {
    padding: 2.5rem;
    bottom: 2.5rem;
  }
  .voice_text {
    margin-bottom: 2.5rem;
    p {
      @extend .montserrat_semibold;
      font-size: $fs16;
      line-height: 1.9rem;
      color: $black-color;
    }
    span {
      @extend .msg_border_icon;
      background-color: $white-color;
      @extend .cursor_pointer;
      img {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
  .sound_icon {
    @extend .text_center;
    span {
      @extend .position_relative;
      @extend .d_block;
      z-index: 0;
      img {
        display: unset;
      }
      &::after {
        content: "";
        @extend .position_absolute;
        width: 8.9rem;
        height: 8.9rem;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
        -webkit-animation: pulsate1 4s;
        animation: pulsate1 4s;
        -webkit-animation-direction: forwards;
        animation-direction: forwards;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $btn_primary;
        opacity: 0.3;
        z-index: -1;
      }
      &.pause_audio {
        &::after {
          background-color: transparent;
          animation-iteration-count: unset;
        }
      }
      @-webkit-keyframes pulsate1 {
        0% {
          -webkit-transform: translate(-50%, -50%) scale(0.6);
          transform: translate(-50%, -50%) scale(0.6);
          opacity: 1;
          opacity: 0.3;
        }
        50% {
          -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
          opacity: 0.3;
        }
        80% {
          -webkit-transform: translate(-50%, -50%) scale(1.1);
          transform: translate(-50%, -50%) scale(1.1);
          opacity: 0.4;
        }
        100% {
          -webkit-transform: translate(-50%, -50%) scale(1.2);
          transform: translate(-50%, -50%) scale(1.2);
          opacity: 0.2;
        }
      }

      @keyframes pulsate1 {
        0% {
          -webkit-transform: translate(-50%, -50%) scale(0.6);
          transform: translate(-50%, -50%) scale(0.6);
          opacity: 0.3;
        }
        50% {
          -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
          opacity: 0.3;
        }
        80% {
          -webkit-transform: translate(-50%, -50%) scale(1.1);
          transform: translate(-50%, -50%) scale(1.1);
          opacity: 0.4;
        }
        100% {
          -webkit-transform: translate(-50%, -50%) scale(1.2);
          transform: translate(-50%, -50%) scale(1.2);
          opacity: 0.2;
        }
      }
    }
  }
  &.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(80%);
    transform: translateY(80%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(80%);
    transform: translateY(80%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(80%);
    transform: translateY(80%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(80%);
    transform: translateY(80%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
.zoomIn {
  animation: zoomIn 1s ease forwards;
}
@keyframes zoomIn {
  0% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes zoomIn {
  0% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.typing {
  @extend .position_absolute;
  bottom: 2rem;
  .ellipsis {
    @extend .d_inline_block;
    width: 0.5rem;
    height: 0.5rem;
    @extend .radius-50;
    background-color: $gray-three;
    margin: 0 0.2rem;
    &.dot_1 {
      animation: bounce 0.8s linear 0.1s infinite;
    }
    &.dot_2 {
      animation: bounce 0.8s linear 0.2s infinite;
    }
    &.dot_3 {
      animation: bounce 0.8s linear 0.3s infinite;
    }
  }
}

@keyframes bounce {
  50% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@-webkit-keyframes bounce {
  50% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.voice_msg_wrapper {
  @extend .d_flex;
  flex-direction: column;
}
.voice_msg {
  background-color: $login_bg;
  border-radius: 2.4rem;
  padding: 0.8rem 1.2rem;
  max-width: 32.2rem;
  .volume_box {
    @extend .d_flex;
    @extend .align_items_center;
    gap: 1.2rem;
    @include media_query(extra_small) {
      gap: 0.5rem;
    }
    p {
      @extend .border_0;
      @extend .p_0;
      @extend .montserrat_regular;
      font-size: $fs14;
      color: $black-color;
      opacity: 0.6;
      background: transparent;
      height: 4rem;
      width: 4rem;
      @extend .d_flex_align;
      @include media_query(extra_small) {
        font-size: $fs12;
      }
      img {
        width: 4rem;
        height: 4rem;
        object-fit: cover;
      }
    }
    .pauseIcon {
      width: 4.2rem;
      height: 4.2rem;
      path {
        width: 4rem;
        height: 4rem;
      }
    }
    .wave {
      max-width: 17rem;
      @include media_query(medium) {
        max-width: 13rem;
      }
      @include media_query(extra_small) {
        max-width: 9rem;
      }
    }
    .duration {
      max-width: 3.3rem;
    }
    .volume {
      width: 2.2rem;
      height: 2.2rem;
    }
  }
}
.message_box .chat_box .chatbox_area .chatting .shared_msg_box p {
  width: 100% !important;
}
.shared_msg_box {
  padding: 3.2rem;
  background-color: $search-input;
  @extend .position_absolute;
  @extend .w_100;
  bottom: 10.7rem;
  left: 0;
  right: 0;
  @include media_query(small) {
    padding: 2.5rem 3.2rem;
    bottom: 2rem;
  }
  @include media_query(extra_small) {
    padding: 4.5rem 2.5rem 2.5rem;
  }
  .close_btn {
    @extend .position_absolute;
    right: 3.2rem;
    span {
      @extend .msg_border_icon;
      background-color: $white-color;
      img {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
    @include media_query(extra_small) {
      right: 2rem;
      top: 0.8rem;
      span {
        width: 3rem;
        height: 3rem;
      }
    }
    @include media_query(small) {
      right: 1.8rem;
    }
  }
  .landscape_view {
    img {
      max-width: 15rem;
      object-fit: cover;
    }
  }
  p {
    margin: 1rem 0;
    @extend .montserrat_semibold;
    font-size: $fs16;
    color: $black-color;
  }
  span {
    @extend .montserrat_regular;
    color: $gray-three;
    font-size: $fs16;
  }
  .message,
  .youtube_info {
    @extend .d_none;
  }
  &.potrait_view {
    max-width: 26.1rem;
    box-sizing: border-box;
    @extend .p_0;
    float: right;
    clear: both;
    position: unset;
    background-color: $white-color;
    @include media_query(small) {
      background-color: transparent;
    }
    .close_btn {
      @extend .d_none;
    }
    .landscape_view {
      background-color: $white-color;
      border: 1px solid $btn_disable;
      @extend .over_hidden;
      min-height: 25rem;
      border-radius: 0.8rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .big_img {
        @extend .w_100;
        max-width: 100%;
        height: 18rem;
        object-fit: cover;
        &.object_revert {
          object-fit: revert;
        }
        &.object_contain {
          object-fit: contain;
        }
      }
      p {
        @extend .d_none;
      }
      span {
        @extend .montserrat_semibold;
        font-size: $fs18;
        color: $black-color;
        line-height: normal;
        @extend .d_block;
        padding: 2.3rem 1.8rem;
        text-transform: capitalize;
      }
      .youtube_info {
        padding-inline: 1.8rem;
        margin-bottom: 2.5rem;
        @extend .d_flex;
        a {
          @extend .montserrat_bold;
          color: $btn_primary;
          font-size: $fs14;
        }
        span {
          margin-left: 1.6rem;
          @extend .p_0;
          img {
            margin-right: 0.7rem;
            @extend .d_inline_block;
            vertical-align: middle;
            width: 2.2rem;
          }
          @extend .montserrat_regular;
          color: $black-color;
          font-size: $fs14;
        }
      }
    }
    .message {
      min-width: 26.1rem;
      @extend .d_block;
      p {
        @extend .d_block;
        @extend .m_0;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0.8rem !important;
      }
    }
    &.other_message {
      float: left;
    }
  }
}
.chat_footer {
  border-top: 0.01rem solid $btn_disable;
  background-color: $white-color;
  // @extend .position_relative;
  position: sticky;
  bottom: 0;
  @extend .w_100;
  // left: 0;
  // right: 0;
  @include media_query(small) {
    background-color: $mobile_bg;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &.chat_footer_opacity {
    pointer-events: none;
    @extend .border_0;
    @include media_query(small) {
      bottom: 0;
    }
    .chat_footer_box {
      opacity: 0.2;
      border-top: 0.01rem solid $btn-disable;
    }
  }
  .chat_footer_box {
    column-gap: 1.2rem;
    padding: 2.5rem 3.2rem;
    @extend .position_relative;
    z-index: 1;
    background-color: $white-color;
    @include media_query(three_extra_large) {
      column-gap: 0.8rem;
    }
    @include media_query(one_extra_large) {
      padding: 2.5rem 1.2rem;
      column-gap: 1.2rem;
    }
    @include media_query(extra_large) {
      column-gap: 0.5rem;
    }
    @include media_query(small) {
      padding: 1.9rem 1.2rem;
      flex-wrap: wrap;
      background-color: $mobile_bg;
      column-gap: 1rem;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
    @include media_query(extra_small) {
      padding-inline: 0.5rem;
      column-gap: 0.5rem;
    }
    .circle_box {
      @extend .msg_border_icon;
      @extend .cursor_pointer;
      @include media_query(small) {
        width: 3rem;
        height: 3rem;
        img {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
      @include trans;
      &:hover {
        background-color: $primary-color;
        img {
          filter: brightness(10);
        }
      }
    }
    .chat_input {
      input {
        font-size: $fs16;
        @extend .montserrat_regular;
        line-height: 2.2rem;
        color: $gray-two;
        padding: 1.6rem 2.5rem;
        background-color: $search-input;
        @extend .radius-50;
        min-width: 32.2rem;
        border: none;
        margin-right: 1.2rem;
        @include media_query(three_extra_large) {
          margin-right: 1rem;
        }
        @include media_query(two_fifth_extra_large) {
          min-width: 23.2rem;
        }
        @include media_query(one_extra_large) {
          margin-right: 1.2rem;
          min-width: 32.2rem;
        }
        @include media_query(two_extra_large) {
          max-width: 16rem;
        }
        @include media_query(extra_large) {
          margin-right: 0.5rem;
        }
        @include media_query(large) {
          max-width: 100%;
        }
        @include media_query(medium) {
          min-width: unset;
          margin-right: 1rem;
        }
        @include media_query(small) {
          padding: 1.1rem 2.5rem;
          width: 26rem;
        }
        @media screen and (max-width: 406px) {
          width: 24rem;
        }
        @include media_query(extra_small) {
          padding: 1rem 1.2rem;
          font-size: 1.2rem;
          width: 170px;
          margin-right: 0.5rem;
        }
        &:active,
        &:focus {
          outline: none;
        }
      }
      .message_textarea_field_wrapper {
        padding: 0rem 1.5rem;
        background-color: $search-input;

        margin-right: 1.2rem;
        @include media_query(three_extra_large) {
          margin-right: 1rem;
        }
        @include media_query(one_extra_large) {
          margin-right: 1.2rem;
        }
        @include media_query(extra_large) {
          margin-right: 0.5rem;
        }
        @include media_query(medium) {
          margin-right: 1rem;
        }
        @include media_query(extra_small) {
          margin-right: 0.5rem;
        }
      }
      .message_textarea_field {
        font-size: $fs16;
        @extend .montserrat_regular;
        line-height: 2.2rem;
        color: $gray-two;
        padding: 1.5rem 0.5rem;
        background-color: transparent;
        min-width: 32.2rem;
        border: none;
        outline: none;
        resize: none;

        @include media_query(two_fifth_extra_large) {
          min-width: 23.2rem;
        }
        @include media_query(one_extra_large) {
          min-width: 32.2rem;
        }
        @include media_query(two_extra_large) {
          max-width: 16rem;
        }

        @include media_query(large) {
          max-width: 100%;
        }
        @include media_query(medium) {
          min-width: unset;
        }
        @include media_query(small) {
          // padding: 1.1rem 2.5rem;
          width: 24rem;
        }
        // @media screen and (max-width: 406px) {
        //   width: 24rem;
        // }
        @include media_query(extra_small) {
          // padding: 1rem 1.2rem;
          font-size: 1.2rem;
          width: 17rem;
        }
        &:active,
        &:focus {
          outline: none;
        }
      }
    }
    .voice_msg {
      min-width: 32.2rem;
      margin-right: 1.2rem;
      @include media_query(medium) {
        min-width: unset;
      }
      @include media_query(small) {
        width: 26rem;
        padding: 0.2rem 1.2rem;
      }
      @include media_query(extra_small) {
        width: 17rem;
        padding: 0.2rem 1.2rem;
        margin-right: 0.5rem;
      }

      .volume_box {
        @extend .justify_content_center;
      }
    }
  }
}

.emoji-picker {
  @extend .d_flex;
  gap: 1.5rem;
  padding: 0.5rem;
  .icon {
    font-size: $fs24;
    @extend .cursor_pointer;
  }
}

textarea.message_textarea_field {
  resize: both;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
textarea.message_textarea_field.scroll_bar_hidden::-webkit-scrollbar {
  width: 0.6rem;
}

textarea.message_textarea_field.scroll_bar_hidden::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: 0.01rem solid transparent;
}

textarea.message_textarea_field.scroll_bar_hidden::-webkit-scrollbar-track {
  background-color: transparent;
}

textarea.message_textarea_field.scroll_bar_hidden {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
