$primary-color: #1e2d66;
$secondary-color: #4287ff;
$green-color: #73c92d;
$yellow-color: #fec348;
$black-two: #1d1d1d;
$black-three: #282828;
$white-color: #ffffff;
$gray-color: #333333;
$gray-two: #4f4f4f;
$gray-three: #828282;
$gray-four: #bdbdbd;
$btn_primary: #1373e7;
$btn_disable: #e0e0e0;
$login_bg: #e8f1fd;
$form_bdr: #298700;
$border-color: #e0e0e0;
$tab-text: #4f4f4f;
$form-input-color:#1d1d1d;
$light-gray:#828282;
$red-required:#D64444;
$error-msg:#EB5757;
$form-lable-color:#333333;
$title-color: #282828;
$light_yellow: #FFF0BB;
$pink_bg: #FBE7E9;
$light_blue: #D8E3FF;
$light_red:#DD2025;
$success-color: #d4f8d3;
$grey-color: #e9eaf0;
$black-color: #000000;
$tab-head: #222222;
$menu-text: #e1ebf9;
$grey-text: #767676;
$table-hover: #f3f8ff;
$blue-color: #0f5cb9;
$separtor-color: #b9bed0;
$success-bg: #fafff8;
$dark-bg: #121b3d;
$form-input-border: #dde0e8;
$form-input-active-bg: #e7f1fd;
$red-required: #d64444;
$error-msg: #eb5757;
$tag-blue: #e7f0fc;
$blue-bg: #e7f1fdab;
$profile-bg:#F2F7FD;
$hr-color:#DCEAFB;
$border_top: #E0E8F6;
$box_shadow: rgba(36, 73, 137, 0.08);
$upload_file: rgba(231, 241, 253, 0.50);
$border_shadow: rgba(30, 45, 102, 0.03);
$search-input:#EFEFEF;
$msg-date:#4D4A4F;
$light_yellow: #FFF0BB;
$project_bg:#d6e2f5;
$mobile_bg:#F8F8F8;
$saffron_yellow:#FEC348;
$progress_bar : #D9D9D9;
$fade-blue:#E7F0FC;
$popup_border:#B9BED0;
$status_green:#D1F3F1;
$thub_border:#B6D4F8;
$play_btn:#2D2D2D;
$scroll-color:#0E56AD;
$reminder-color: #FFE6D8;
$dark_gray: #8a93a2;
$msg_link: #FFCC00;
$content-disable: rgb(231 241 253 / 50%);

$breakpoints: (
  mac_device: 2560px,
  three_extra_large: 1440px,
  two_fifth_extra_large: 1380px,
  one_extra_large: 1280px,
  two_extra_large: 1140px,
  extra_large: 1023px,
  large: 989px,
  medium: 767px,
  small: 579px,
  md_small: 480px,
  extra_small: 392px,
);
