// home full screen style
.common_title_detail {
  @extend .d_flex;
  @extend .justify_content_between;
  margin-bottom: 1.5rem;
  h6 {
    color: $black-color;
    @extend .montserrat_medium;
  }
  .view_all_details {
    font-size: $fs16;
    color: $btn_primary;
    @extend .montserrat_regular;
    @extend .cursor_pointer;
  }
}
.chat_announ_sec {
  @extend .d_flex;
  @extend .justify_content_between;
  @include media_query(three_extra_large) {
    flex-wrap: nowrap !important;
  }
  @include media_query(two_fifth_extra_large) {
    flex-wrap: wrap !important;
  }
  @include media_query(medium) {
    margin: 0 1.5rem;
  }
  @include media_query(md_small) {
    display: block;
  }
  .chat_message_part {
    @extend .w_100;
    max-width: 90.4rem;
    @extend .d_flex;
    @extend .flex_wrap;
  }
}
.empty_message {
  p {
    color: $primary-color;
    font-size: $fs16;
    @extend .montserrat_medium;
    @include media_query(small) {
      font-size: $fs12;
    }
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  @extend .d_block;
  background: url(../images/Canedar.svg) no-repeat;
  width: 2.4rem;
  height: 2.4rem;
  @extend .cursor_pointer;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  @extend .d_block;
  background: url(../images/time_clock.svg) no-repeat;
  width: 2.4rem;
  height: 2.4rem;
  @extend .cursor_pointer;
  margin-top: 0.6rem;
}
.message_announce_box {
  @extend .d_flex;
  @extend .w_100;
  @include media_query(three_extra_large) {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  @include media_query(large) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  @include media_query(md_small) {
    justify-content: center;
  }
}
// message section style
.message_sec {
  @extend .w_100;
  margin-left: 3.2rem;
  margin-top: 2.5rem;
  @include media_query(mac_device) {
    width: 100%;
    min-width: 34.6rem;
  }
  @include media_query(one_extra_large) {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  @include media_query(large) {
    max-width: 100%;
  }
  @include media_query(medium) {
    margin-left: 0;
    margin-right: 0;
  }
  @include media_query(small) {
    margin-top: 7rem;
  }
  @include media_query(md_small) {
    order: 2;
    margin: 2.5rem auto auto auto;
  }
  .message_boxs {
    border: 0.1rem solid $btn_disable;
    border-radius: 0.8rem;
    padding: 2.5rem;
    height: 28rem;
    overflow: auto;
    scrollbar-color: $scroll-color $form-input-active-bg;
    scrollbar-width: thin;
    @extend .position_relative;
    box-shadow: 0px 4px 40px 0px rgba(36, 73, 137, 0.08);
    @media (max-width: 1920px) {
      width: 100%;
    }
    @include media_query(large) {
      width: 100%;
      margin: auto;
      order: 1;
    }
    .empty_message {
      @extend .position_absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .chat_list {
      @extend .d_flex;
      margin-bottom: 2rem;
      @extend .cursor_pointer;
      &:last-child {
        margin-bottom: 0;
      }
      .chat_img {
        @extend .w_100;
        max-width: 4rem;
        height: 4rem;
        border-radius: 4rem;
        img {
          @extend .w_100;
          @extend .h_100;
          object-fit: cover;
          border-radius: 5rem;
        }
      }
      .chat_message {
        margin-left: 1rem;
        p {
          font-size: $fs16;
          color: $black-color;
          @extend .montserrat_semibold;
          @include media_query(two_extra_large) {
            text-overflow: ellipsis;
            width: auto;
            overflow: hidden;
            min-height: 0;
            max-width: 16rem;
            white-space: nowrap;
          }
          @include media_query(large) {
            max-width: 100%;
          }
          @include media_query(md_small) {
            max-width: 15.5rem;
          }
        }
        small {
          font-size: $fs14;
          color: $gray-three;
          @extend .montserrat_medium;
          text-overflow: ellipsis;
          @extend .white_space_nowrap;
          @extend .d_inline_block;
          @extend .w_100;
          max-width: 15rem;
          @extend .over_hidden;
        }
      }
      .chat_time {
        margin-left: auto;
        @extend .text_right;
        @extend .white_space_nowrap;
        p {
          font-size: 1.3rem;
          color: $gray-three;
          @extend .montserrat_medium;
        }
        img {
          margin-left: auto;
        }
        .chat_notification {
          background-color: $btn_primary;
          color: $white-color;
          font-size: 1rem;
          border-radius: 1.8rem;
          padding: 0.3rem 0.7rem;
          width: 1.8rem;
          height: 1.8rem;
          @extend .d_flex_center;
          margin-left: auto;
          @extend .montserrat_bold;
          margin-top: 0.2rem;
        }
      }
    }
  }
}

// announcement section style
.announcements_sec {
  @extend .w_100;
  margin-left: 3.2rem;
  margin-top: 2.5rem;
  @include media_query(mac_device) {
    width: 100%;
    min-width: 34.6rem;
  }
  @include media_query(two_fifth_extra_large) {
    margin-right: 3.2rem;
  }
  @include media_query(one_extra_large) {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
  @include media_query(large) {
    max-width: 100%;
  }
  @include media_query(medium) {
    margin-left: 0;
    margin-right: 0;
    order: 2;
  }
  @include media_query(small) {
    margin-top: 7rem;
  }
  @include media_query(md_small) {
    order: 1;
  }
  .common_title_detail {
    h6 {
      @extend .white_space_nowrap;
    }
    p {
      @extend .white_space_nowrap;
      padding-left: 1rem;
    }
  }
  .announcements_details {
    border: 0.1rem solid $btn_disable;
    border-radius: 0.8rem;
    padding: 2.5rem 2.5rem 1rem 2.5rem;
    height: 28rem;
    overflow: auto;
    scrollbar-color: $scroll-color $form-input-active-bg;
    scrollbar-width: thin;
    @extend .position_relative;
    box-shadow: 0px 4px 40px 0px rgba(36, 73, 137, 0.08);
    @media (max-width: 1920px) {
      width: 100%;
    }
    @include media_query(one_extra_large) {
      width: 100%;
    }
    @include media_query(large) {
      width: 100%;
      margin: auto;
    }
    @include media_query(small) {
      border: transparent;
      padding: 0;
    }
    .empty_message {
      @extend .position_absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .set_reminder_sec {
      border: 0.1rem solid $btn_disable;
      border-radius: 0.8rem;
      padding: 1.5rem;
      margin-bottom: 1.5rem;
      @include media_query(small) {
        margin: 1.5rem;
      }
      .reminder_list {
        margin-bottom: 1rem;
        @extend .d_flex;
        .reminder_added {
          color: $primary-color;
          padding-left: 1rem;
        }
        p {
          @extend .montserrat_semibold;
        }
        a {
          margin-left: auto;
          @extend .montserrat_semibold;
          font-size: $fs14;
          color: $btn_primary;
          text-decoration: underline;
        }
      }
      .task_list {
        p {
          color: $black-color;
          @extend .montserrat_semibold;
          margin-bottom: 2rem;
        }
        ul {
          margin-left: 2rem;
          li {
            color: $black-color;
            font-size: $fs14;
            @extend .montserrat_semibold;
          }
        }
      }
      &.private_sec {
        @include media_query(md_small) {
          border: 1.5px solid $btn_primary;
        }
      }
      .private_title {
        background-color: $hr-color;
        @extend .d_inline_block;
        padding: 0.3rem 0.9rem;
        border-radius: 0.5rem;
        color: $black-color;
        @extend .montserrat_regular;
        margin-bottom: 1rem;
      }
    }
  }
}

// project assign style
.project_assign_sec {
  margin-left: 3.2rem;
  margin-top: 3rem;
  margin-bottom: 2.5rem;
  min-width: 72.1rem;
  @extend .h_100;
  @include media_query(two_fifth_extra_large) {
    margin-right: 3.2rem;
  }
  @include media_query(one_extra_large) {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  @include media_query(large) {
    order: 3;
    min-width: 0;
  }
  @include media_query(medium) {
    margin-left: 0;
    margin-right: 0;
  }
  @include media_query(md_small) {
    order: 3;
  }
  .table_wrapper {
    padding: 0;
    box-shadow: 0px 4px 40px 0px rgba(36, 73, 137, 0.08);
  }
  .project_list {
    max-height: 46.3rem;
    overflow-y: hidden;
    overflow-x: auto;
    @extend .position_relative;
    .empty_message {
      @extend .position_absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    table {
      &.more_page {
        table-layout: fixed;
        thead {
          display: table;
          @extend .w_100;
          @include media_query(large) {
            width: 78.3rem;
          }
          @include media_query(small) {
            width: 53rem;
          }
          tr {
            @extend .w_100;
            display: table;
            table-layout: fixed;

            td {
              &:nth-child(2) {
                @include media_query(small) {
                  display: inline-block;
                  width: 20rem !important;
                }
              }
              &:nth-child(3) {
                @include media_query(small) {
                  display: inline-block;
                }
              }
              &:nth-child(4) {
                @include media_query(small) {
                  display: inline-block;
                  width: 18rem !important;
                }
              }
              &:last-child {
                width: 15.3rem !important;
                @include media_query(small) {
                  display: inline-block;
                }
              }
              p {
                color: $tab-head;
                @extend .montserrat_bold;
                font-size: $fs14;
              }
            }
          }
        }
        tbody {
          @extend .d_block;
          @extend .w_100;
          overflow: auto;
          max-height: 40.7rem;
          scrollbar-color: $scroll-color $form-input-active-bg;
          scrollbar-width: thin;
          @include media_query(large) {
            width: 78.3rem;
            height: 40.7rem;
          }
          tr {
            @extend .w_100;
            display: table;
            @include media_query(three_extra_large) {
              table-layout: fixed;
            }
            td {
              padding: 1.8rem 1.6rem;
              &:nth-child(2) {
                @include media_query(small) {
                  width: 20rem !important;
                }
              }
              &:nth-child(3) {
                @include media_query(small) {
                  display: inline-block;
                  border: none;
                }
              }
              &:nth-child(4) {
                @include media_query(small) {
                  display: inline-block;
                  border: none;
                }
              }
              &:last-child {
                width: 15rem !important;
                padding-right: 2.4rem !important;
                position: static !important;
                @include media_query(small) {
                  right: unset !important;
                }
              }
              p:not(:first-child) {
                @extend .montserrat_medium;
                font-size: $fs14;
                padding-left: 1rem;
                text-overflow: ellipsis;
                width: auto;
                min-height: 0;
                max-width: 30rem;
                @extend .white_space_nowrap;
                @extend .over_hidden;
              }
              .redirect_svg_icon {
                @extend .w_100;
                max-width: 2.2rem;
                height: 2.2rem;
                margin-left: auto;
                img {
                  @extend .w_100;
                  @extend .h_100;
                }
              }
              .inprogress_tab {
                @extend .white_space_nowrap;
                &.pink_bg {
                  span {
                    background-color: $pink_bg;
                  }
                }
                &.light_blue_bg {
                  span {
                    background-color: $light_blue;
                  }
                }
                span {
                  font-size: $fs14;
                  color: $tab-head;
                  font-family: "Montserrat_regular";
                  background-color: $light_yellow;
                  padding: 0.3rem 0.9rem;
                  border-radius: 0.4rem;
                }
              }
              .assign_img_txt {
                @extend .d_flex;
                @extend .align_items_center;
                span {
                  @extend .w_100;
                  max-width: 2.4rem;
                  height: 2.4rem;
                  border-radius: 5rem;
                  @extend .d_inline_block;
                  img {
                    @extend .w_100;
                    @extend .h_100;
                    object-fit: cover;
                    border-radius: 5rem;
                  }
                }
                p {
                  font-size: $fs16;
                  color: $gray-two;
                  @extend .montserrat_medium;
                  text-overflow: ellipsis;
                  width: auto;
                  @extend .over_hidden;
                  white-space-collapse: collapse;
                  max-width: 18.2rem;
                  min-height: 0;
                  text-wrap: nowrap;
                }
              }
              .custom_progressbar {
                &.content_bottom {
                  @extend .d_flex;
                  @extend .align_items_center;
                  span {
                    @extend .white_space_nowrap;
                    font-size: $fs14;
                    padding-left: 1rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//   add reminder popup
.add_remind_overlay {
  @extend .position_fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  @extend .w_100;
  @extend .d_flex_center;
  background: rgba(22, 35, 83, 0.3);
  z-index: 111;
  @include popup_animate;
}
.add_reminder_popup {
  border-radius: 2rem;
  background: $white-color;
  box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
  @extend .w_100;
  max-width: 73.3rem;
  margin: auto;
  padding: 3.2rem;
  @include media_query(one_extra_large) {
    max-width: 50rem;
  }
  @include media_query(medium) {
    max-width: 45rem;
    margin: auto 1.5rem;
  }
  .add_remind_title {
    @extend .d_flex;
    @extend .align_items_center;
    margin-bottom: 3.4rem;
    h5 {
      margin-left: auto;
      @include media_query(small) {
        margin: 0;
        font-size: $fs20;
      }
    }
    .event_popup_close {
      margin-left: auto;
      @extend .cursor_pointer;
    }
  }
  label {
    margin-bottom: 0.8rem;
  }
  .name_filed {
    @extend .w_100;
    max-width: 32rem;
    @include media_query(one_extra_large) {
      max-width: 100%;
    }
  }
  .add_reminder_btns {
    @extend .d_flex;
    @extend .justify_content_center;
    gap: 0.8rem;
    .btn_outline {
      max-width: 20rem;
      padding: 1rem 2.4rem;
      &:hover {
        color: $white-color;
      }
      &.dele_btns {
        border: 0.2rem solid $light_red;
        color: $gray-color;
        &:hover {
          background-color: $light_red;
          color: $white-color;
        }
      }
      @include media_query(small) {
        font-size: $fs14;
      }
    }
    .btn_primary {
      max-width: 22.8rem;
      padding: 1.2rem 2.4rem;
      @extend .justify_content_center;
      &:hover {
        color: $white-color;
      }
      @include media_query(small) {
        max-width: 100%;
        padding: 1.2rem 1.4rem;
        font-size: $fs14;
        white-space: nowrap;
      }
    }
  }
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      width: 31.8rem;
      right: 0 !important;
      margin-left: auto;
      .react-datepicker {
        .react-datepicker__triangle {
          transform: translate(165px, 0px) !important;
        }
        .react-datepicker__month-container {
          .react-datepicker__day-names {
            gap: unset;
            @extend .justify_content_between;
          }
        }
      }
    }
  }
  // time picker
  .rc-time-picker {
    @extend .d_flex_align;
    .rc-time-picker-input {
      border: 0;
      padding: 0;
      font-size: $fs16;
      @extend .cursor_pointer;
      &:focus-visible {
        outline: 0;
      }
    }
    .rc-time-picker-icon {
      background: url(../images/time_clock.svg) no-repeat;
      width: 2.8rem;
      height: 2.6rem;
      @extend .cursor_pointer;
    }
  }
  // time picker select
  .rc-time-picker-panel {
    .rc-time-picker-panel-inner {
      top: 5rem;
      @extend .position_absolute;
      ul {
        li {
          text-transform: uppercase;
        }
      }
      .rc-time-picker-panel-input-wrap {
        @extend .d_none;
      }
    }
  }
}
// all announcement
.announcement_overlay {
  @extend .position_fixed;
  @extend .w_100;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  @extend .d_flex_center;
  background-color: rgba(22, 35, 83, 0.3);
  z-index: 111;
}
.announcements_popup {
  border-radius: 2rem;
  background: $white-color;
  box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
  padding: 3.2rem;
  padding-right: 0;
  @extend .w_100;
  max-width: 81.7rem;
  margin: auto;
  max-height: 89.4rem;
  @include media_query(one_extra_large) {
    height: auto;
    max-width: 50rem;
    margin: auto 1.5rem;
  }
  &::after {
    content: "";
    border-radius: 0 0 2rem 2rem;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      $white-color 78.13%
    );
    @extend .position_absolute;
    @extend .w_100;
    height: 9.5rem;
    bottom: 0;
    right: 0;
  }
  .all_announcement_title {
    @extend .d_flex;
    @extend .justify_content_between;
    margin-bottom: 3.2rem;
    padding-right: 3.2rem;
    .announcement_popup_close {
      @extend .cursor_pointer;
    }
    h5 {
      @include media_query(small) {
        font-size: $fs18;
        font-family: "Montserrat_medium";
      }
    }
  }
  .overflow_scroll_popup {
    overflow: auto;
    @extend .h_100;
    max-height: 74.8rem;
    padding-right: 3.2rem;
    scrollbar-width: thin;
    scrollbar-color: $scroll-color $form-input-active-bg;
    .announcements_popup_details {
      border-radius: 0.8rem;
      border: 1.5px solid $btn_disable;
      padding: 1.5rem;
      margin-bottom: 3.2rem;
      .reminder_time_link {
        @extend .d_flex;
        @extend .justify_content_between;
        margin-bottom: 1rem;
        @include media_query(extra_small) {
          justify-content: center;
          flex-wrap: wrap;
        }
        p {
          @extend .montserrat_semibold;
          @include media_query(extra_small) {
            margin-bottom: 1rem;
          }
        }
        .reminder_reply_link {
          ul {
            @extend .d_flex;
            @extend .align_items_start;
            li {
              @extend .list_unstyled;
              &.vr_line {
                color: $btn_primary;
                font-size: $fs14;
                @extend .montserrat_semibold;
                padding: 0 0.5rem;
              }
              a {
                color: $btn_primary;
                font-size: $fs14;
                @extend .montserrat_semibold;
                padding: 0 0.5rem;
                @extend .white_space_nowrap;
                &.reminder_added {
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
      .reminder_desc {
        p {
          color: $black-color;
          @extend .montserrat_semibold;
          padding-bottom: 1.5rem;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  border-radius: 2rem;
  background: $white-color;
  box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
  padding: 3.2rem;
  padding-right: 0;
  @extend .w_100;
  max-width: 81.7rem;
  margin: auto;
  @extend .position_relative;
  &::after {
    content: "";
    border-radius: 0 0 2rem 2rem;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      $white-color 78.13%
    );
    @extend .position_absolute;
    @extend .w_100;
    height: 7.5rem;
    bottom: 0;
    right: 0;
  }
  .all_announcement_title {
    @extend .d_flex;
    @extend .justify_content_between;
    margin-bottom: 3.2rem;
    padding-right: 3.2rem;
    .announcement_popup_close {
      @extend .cursor_pointer;
    }
  }
  .overflow_scroll_popup {
    overflow: auto;
    max-height: 77.8rem;
    padding-right: 3.2rem;
    @include media_query(one_extra_large) {
      max-height: 50rem;
    }
    .announcements_popup_details {
      border-radius: 0.8rem;
      border: 1.5px solid $btn_disable;
      padding: 1.5rem;
      margin-bottom: 3.2rem;
      .reminder_time_link {
        @extend .d_flex;
        @extend .justify_content_between;
        margin-bottom: 1rem;
        p {
          @extend .montserrat_semibold;
        }
        .reminder_reply_link {
          ul {
            @extend .d_flex;
            li {
              @extend .list_unstyled;
              margin: 0;
              &.vr_line {
                color: $btn_primary;
                font-size: $fs14;
                @extend .montserrat_semibold;
                padding: 0 0.5rem;
              }

              a {
                color: $btn_primary;
                font-size: $fs14;
                @extend .montserrat_semibold;
                padding: 0 0.5rem;
                text-decoration: underline;
              }
            }
          }
        }
      }
      .reminder_desc {
        p {
          color: $black-color;
          @extend .montserrat_semibold;
          padding-bottom: 1.5rem;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
// calendar style
.chat_calendar {
  margin-top: 2.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  @include media_query(three_extra_large) {
    width: 100%;
    max-width: 43rem;
  }
  @include media_query(medium) {
    margin-left: 0;
  }
  .demo-app {
    border: 0.1rem solid $btn_disable;
    padding: 1.2rem;
    border-radius: 0.8rem;
    box-shadow: 0px 4px 40px 0px rgba(36, 73, 137, 0.08);
    min-height: 91.5rem;
    overflow: hidden;
    margin-bottom: 2.5rem;
    @extend .position_relative;
    @include media_query(one_extra_large) {
      margin: auto;
    }
    &::before {
      content: "";
      background: url(../images/calender_rec.png);
      height: 26rem;
      left: 0;
      right: 0;
      margin: 1.2rem;
      top: 0;
      background-size: contain;
      @extend .position_absolute;
      z-index: -1;
      background-repeat: no-repeat;
      @include media_query(mac_device) {
        border-radius: 1rem 1rem 0 0;
      }
      @include media_query(md_small) {
        border-top-right-radius: 1rem;
      }
    }
    .month_week_btns {
      background-color: $white_color;
      @extend .text_center;
      margin-top: 2rem;
      margin-bottom: 1rem;
      @extend .position_relative;
      button {
        color: $btn_primary;
        @extend .montserrat_regular;
        font-size: $fs16;
      }
      .show_all_category_list {
        @extend .position_absolute;
        right: 0;
        color: $btn_primary;
        font-size: $fs16;
        @extend .montserrat_semibold;
        margin-left: 3.5rem;
        &.Monthly {
          margin-top: 3.5rem;
        }
      }
    }
    .next_prev_btns {
      .prev_btn {
        @extend .position_absolute;
        left: 3rem;
        top: 9rem;
        @include media_query(two_fifth_extra_large) {
          left: 2.5rem;
          top: 8.5rem;
        }
        @include media_query(medium) {
          left: 1.5rem;
          top: 7.5rem;
        }
        @include media_query(md_small) {
          left: 2.5rem;
          top: 8.5rem;
        }
      }
      .next_btn {
        @extend .position_absolute;
        right: 3rem;
        top: 9rem;
        transform: rotate(180deg);
        @include media_query(two_fifth_extra_large) {
          right: 2.5rem;
          top: 8.5rem;
        }
        @include media_query(medium) {
          right: 1.5rem;
          top: 7.5rem;
        }
        @include media_query(md_small) {
          right: 2.5rem;
          top: 8.5rem;
        }
      }
    }
    .date_days_txt {
      color: $gray-two;
      margin-top: 1.5rem;
      @extend .montserrat_semibold;
      font-size: $fs16;
      @extend .text_center;
      @include media_query(small) {
        font-size: $fs16;
      }
    }
    .fc {
      /* the calendar root */
      min-width: 35rem;
      max-width: 40rem;
      @extend .montserrat_semibold;
      font-size: 1.5rem;
      @include media_query(mac_device) {
        margin: auto;
      }
      @include media_query(md_small) {
        min-width: 100%;
      }
      .fc-daygrid-day-frame {
        padding-top: 1.5rem;
      }
    }
    .demo-app-main {
      padding: 2.4rem 2.8rem;
      @extend .d_inline_block;
      @include media_query(three_extra_large) {
        width: 100%;
        max-width: 41rem;
        padding: 2.2rem;
      }
      @include media_query(medium) {
        padding: 1.2rem;
        max-width: 40.7rem;
      }
      @include media_query(md_small) {
        padding: 2.2rem;
      }
      &.week_view_cal {
        .month_week_btns {
          margin-top: 7rem;
          background-color: transparent;
        }
        .online_event_wrapper {
          min-height: 55rem;
          margin-top: 3rem;
        }
      }
      .calendar_style {
        border-radius: 0.8rem;
        border: 0.1rem solid $btn_disable;
        background-color: $white-color;
        box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
        .fc .fc-col-header-cell-cushion {
          @include media_query(md_small) {
            font-size: $fs14;
          }
        }
        .month_year_sec {
          @extend .text_center;
          padding: 1.5rem 0;
          @extend .d_flex_center;
          gap: 2.3rem;
          @include media_query(md_small) {
            gap: 2rem;
          }
          select {
            appearance: none;
            &#month {
              @extend .montserrat_medium;
              font-size: $fs16;
              background: url(../images/Polygon_calendar.svg) no-repeat;
              background-position: 100%;
              padding: 1rem 2rem;
              padding-left: 0.5rem;
              @extend .cursor_pointer;
              @include media_query(md_small) {
                font-size: $fs14;
              }
            }
            &#year {
              @extend .montserrat_medium;
              font-size: $fs16;
              background: url(../images/Polygon_calendar.svg) no-repeat;
              background-position: 100%;
              padding: 1rem 2rem;
              padding-left: 0.5rem;
              @extend .cursor_pointer;
              @include media_query(md_small) {
                font-size: $fs14;
              }
            }
          }
        }
        .fc-media-screen {
          .fc-dayGridWeek-view {
            padding-top: 3rem;
            table {
              width: 100% !important;
              padding: 0 2.5rem;
            }
            .fc-scrollgrid-sync-inner {
              font-size: 1.2rem;
            }
            .fc-day {
              &.fc-col-header {
                width: 4rem;
                height: 6rem;
              }
              .fc-col-header-cell-cushion {
                border: 0.5px solid transparent;
              }
            }
            .month_week_btns {
              margin-bottom: 2.5rem;
            }
            .fc-col-header-cell-cushion {
              @extend .cursor_pointer;
              padding: 0.9rem 0.4rem;
              @include media_query(md_small) {
                font-size: 1.2rem;
              }
              @include media_query(extra_small) {
                font-size: 1rem;
              }
              .weekDay {
                @include media_query(extra_small) {
                  font-size: 1rem;
                }
              }
              &.selected-date {
                background-color: $form-input-active-bg;
                color: $black-color;
                border: 0.05rem solid $btn_primary;
                border-radius: 1rem;
                width: 4rem;
                height: 6rem;
                @include media_query(extra_small) {
                  width: 3rem;
                  height: 5rem;
                }
                .custom-day-header {
                  p {
                    &.weekDay {
                      color: $gray-three;
                      @include media_query(md_small) {
                        font-size: $fs12;
                      }
                      @include media_query(extra_small) {
                        font-size: 1rem;
                      }
                    }
                  }
                }
              }
            }
            .fc-day-future {
              .selected-date {
                .fc-daygrid-day-top {
                  background-color: $form-input-active-bg;
                  color: $black-color;
                  border: 0.5px solid $btn_primary;
                  border-radius: 1rem;
                  width: 4rem;
                  height: 6rem;
                  @include media_query(extra_small) {
                    width: 3.5rem;
                    height: 5rem;
                  }
                }
                .custom-day-header {
                  .weekDay {
                    color: $gray-three;
                    @include media_query(md_small) {
                      font-size: $fs12;
                    }
                    @include media_query(extra_small) {
                      font-size: 1rem;
                    }
                  }
                }
              }
            }
          }
          .fc-day {
            a.fc-daygrid-day-number {
              border: 0.5px solid transparent;
              width: 2.6rem;
              height: 2.6rem;
              border-radius: 5rem;
              @extend .d_flex_center;
              font-size: $fs14;
              @include media_query(extra_small) {
                font-size: $fs12;
              }
              .custom-day-header {
                @include media_query(extra_small) {
                  font-size: $fs12;
                }
              }
            }
          }
          .fc-daygrid-day {
            &.selected-date {
              background-color: transparent;
              a.fc-daygrid-day-number {
                width: 2.6rem;
                height: 2.6rem;
                border: 0.5px solid $btn_primary;
                border-radius: 5rem;
                background-color: $form-input-active-bg;
                color: $black-color;
                @extend .d_flex_center;
              }
            }
          }
          .fc-daygrid-day-number {
            @extend .cursor_pointer;
          }
        }
        .fc-dayGridMonth-view {
          @include media_query(small) {
            z-index: 0;
          }
          .fc-daygrid-day-events {
            @extend .d_flex_align;
            gap: 0.5rem;
            .fc-daygrid-event-harness {
              .reminder_dot {
                border-color: $saffron_yellow;
                @extend .m_0;
              }
            }
            .fc-daygrid-day-bottom {
              @extend .d_none;
            }
          }
          .fc-daygrid-dot-event {
            @extend .d_flex;
            @extend .justify_content_center;
            margin: 0;
            &:hover {
              background-color: transparent;
            }
            .fc-daygrid-event-dot {
              margin: 0;
            }
          }
        }
        .fc-dayGridWeek-view {
          .fc-daygrid-day-events {
            top: -1.6rem;
          }
        }
        .fc-day-today {
          background-color: transparent;
          .fc-daygrid-day-number {
            background-color: $primary-color !important;
            color: $white-color !important;
            border: none !important;
            border-radius: 5rem;
            width: 2.6rem;
            height: 2.6rem;
            @extend .d_flex_center;
            padding: 0.6rem;
          }
        }

        .fc-dayGridWeek-view {
          .fc-daygrid-day-events {
            @extend .d_flex_align;
            gap: 0.5rem;
            .fc-daygrid-event-harness {
              .reminder_dot {
                border-color: $saffron_yellow;
                @extend .m_0;
              }
            }
            .fc-daygrid-day-bottom {
              @extend .d_none;
            }
          }
          .fc-day-today {
            .fc-col-header-cell-cushion {
              background-color: $primary-color !important;
              color: $white-color !important;
              border: none !important;
              border-radius: 1rem;
              width: 4rem;
              height: 6rem;
              @extend .d_flex_center;
              @include media_query(extra_small) {
                width: 3rem;
                height: 5rem;
              }
              .custom-day-header {
                .weekDay {
                  color: $white-color !important;
                  @include media_query(md_small) {
                    font-size: $fs12;
                  }
                  @include media_query(extra_small) {
                    font-size: 1rem;
                  }
                }
              }
            }
          }
          .fc-daygrid-dot-event {
            justify-content: center;
            margin: 0;
            &:hover {
              background-color: transparent;
            }
            .fc-daygrid-event-dot {
              margin: 0;
            }
          }
        }
      }
      .online_event_wrapper {
        max-height: 34.5rem;
        overflow: auto;
        padding-right: 1.5rem;
        scrollbar-width: thin;
        scrollbar-color: $scroll-color $form-input-active-bg;
        .online_event_box {
          border-radius: 0.8rem;
          border: 1.5px solid $btn_disable;
          padding: 1.5rem;
          margin-bottom: 1.5rem;
          &:first-child {
            margin-top: 2rem;
          }
          &:last-child {
            margin-bottom: 0;
          }
          .reminder_txt {
            background-color: $reminder-color;
            color: $tab-head;
            @extend .d_inline_block;
            padding: 0.3rem 0.9rem;
            border-radius: 0.5rem;
            margin-bottom: 1.5rem;
            margin-right: 1rem;
          }
          .event_note {
            color: $black-color;
            @extend .montserrat_semibold;
          }
          .mentor_rights{
            padding-top: 1.4rem;
            .mentor_rt_title{
              h5{
                color: $gray-three;
                font-size: $fs16;
                @extend .montserrat_regular;
                margin-bottom: 0.5rem;
                line-height: 1.22;
              }
            }
            .mentor_status_list{
              ul{
                flex-grow: 1;
                li{
                  color: $gray-three;
                  font-size: $fs14;
                  @extend .montserrat_medium;
                  font-weight: $fw500;
                  &:not(:last-child){
                    margin: 0 0 4px;
                  }
                  span{
                    @extend .montserrat_bold;
                    color: $primary-color;
                  }
                }
              }
              .more_mentor_status{
                flex-shrink: 0;
                a{
                  min-width: 45px;
                  padding: 7px;
                  font-size: $fs12;
                  @extend .montserrat_medium;
                  color: $btn_primary;
                  border: 1px solid $thub_border;
                  background: $hr-color;
                  border-radius: 70px;
                  @extend .d_block;
                  @extend .text_center;
                  span{
                    margin-left: 1px;
                  }
                }
              }
            }
          }
          .event_location_btn {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding-top: 1.5rem;
            gap: 0.8rem;
            button {
              min-width: auto;
              font-size: $fs12;
              @extend .justify_content_center;
              &.border_btn {
                border-color: $light_red;
                color: $black-color;
                &::after {
                  background-color: $light_red;
                }
              }
              &.exp {
                width: 18rem;
                &.btn_primary {
                  // background-color: rgba(19, 115, 231, 0.1);
                  background-color: transparent;
                  color: $btn_primary;
                  &:hover {
                    &.btn {
                      &:after {
                        opacity: 0;
                      }
                    }
                  }
                }
                &.border_btn {
                  // background-color: rgba(221, 32, 37, 0.1);
                  background-color: transparent;
                  color: $light_red;
                  border-color: rgba(221, 32, 37, 0);
                  &:hover {
                    &.btn {
                      &:after {
                        opacity: 0;
                      }
                    }
                  }
                }
              }
              &.btn {
                &:hover {
                  background-color: transparent;
                }
              }
              &:hover {
                color: $white-color;
              }
            }
          }
          .online_event_txt {
            background-color: $light_blue;
            color: $tab-head;
            padding: 0.3rem 0.9rem;
            border-radius: 0.5rem;
            @extend .d_inline_block;
            @extend .montserrat_regular;
            margin-bottom: 1.5rem;
            margin-right: 1rem;
            &.disable {
              background-color: $btn_disable !important;
            }
          }
          .income_txt {
            @extend .montserrat_semibold;
            color: $black-color;
          }
          .time_detail {
            @extend .montserrat_regular;
            color: $black-color;
          }
          .event_boxes_btns {
            @extend .d_flex_between;
            gap: 0.8rem;
            margin-top: 1.5rem;
            .btn_outline {
              max-width: 15.7rem;
              padding: 1.3rem 1.7rem;
              @extend .d_flex_center;
              border: 0.1rem solid $red-required;
              font-size: $fs12;
              &:hover {
                background-color: $red-required;
                color: $white-color;
              }
            }
            .btn_primary {
              max-width: 15.7rem;
              padding: 1.1rem 1.7rem;
              @extend .d_flex_center;
              font-size: $fs12;
              &:hover {
                color: $white-color;
              }
            }
            .month_week_btns {
              button {
                color: $btn_primary;
                @extend .montserrat_regular;
                font-size: $fs16;
              }
            }
            .date_days_txt {
              color: $gray-two;
              @extend .montserrat_semibold;
              font-size: $fs20;
              @extend .text_center;
            }
            .fc {
              /* the calendar root */
              min-width: 35rem;
              max-width: 40rem;
              @extend .montserrat_semibold;
              font-size: 1.5rem;
            }
            .demo-app-main {
              padding: 2.4rem 2.8rem;
              @extend .d_inline_block;
              &.week_view_cal {
                .month_week_btns {
                  margin-top: 10rem;
                }
              }
              .calendar_style {
                border-radius: 0.8rem;
                border: 0.1rem solid $btn_disable;
                background-color: $white-color;
                box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
                .month_year_sec {
                  @extend .text_center;
                  padding: 1.5rem 0;
                  @extend .d_flex_center;
                  gap: 2.3rem;
                  select {
                    appearance: none;
                    &#month {
                      @extend .montserrat_medium;
                      font-size: $fs16;
                      background: url(../images/Polygon_calendar.svg) no-repeat;
                      background-position: 75%;
                      padding-right: 1.2rem;
                      @include media_query(md_small) {
                        font-size: $fs12;
                      }
                    }
                    &#year {
                      @extend .montserrat_medium;
                      font-size: $fs16;
                      background: url(../images/Polygon_calendar.svg) no-repeat;
                      background-position: 100%;
                      padding-right: 2.2rem;
                      @include media_query(md_small) {
                        font-size: $fs12;
                      }
                    }
                  }
                }
                .fc-media-screen {
                  .fc-dayGridWeek-view {
                    padding-top: 3rem;
                    table {
                      width: 100% !important;
                    }
                    // .custom-day-header{
                    //   p{
                    //     &.weekDay{
                    //       @include media_query(extra_small){
                    //         font-size: 1rem;
                    //       }
                    //   }

                    //   }
                    // }
                    .fc-scrollgrid-sync-inner {
                      font-size: $fs12;
                    }
                  }
                }
                .fc-dayGridMonth-view {
                  .fc-theme-standard {
                    td {
                      border: 0.1rem solid $btn_disable;
                    }
                  }
                }
                .fc-dayGridMonth-view {
                  .fc-daygrid-dot-event {
                    justify-content: center;
                    margin: 0;
                    &:hover {
                      background-color: transparent;
                    }
                  }
                }
                .fc-day-today {
                  background-color: transparent;
                  .fc-daygrid-day-number {
                    background-color: $primary-color;
                    color: $white-color;
                    border-radius: 5rem;
                    width: 2.6rem;
                    height: 2.6rem;
                    @extend .d_flex_center;
                    padding: 0.6rem;
                    border: 0.05rem solid transparent;
                  }
                }

                .fc-dayGridWeek-view {
                  .fc-day-today {
                    .fc-col-header-cell-cushion {
                      background-color: $primary-color;
                      color: $white-color;
                      border-radius: 1rem;
                      padding: 1.1rem 0.5rem;
                    }
                  }
                  .fc-daygrid-dot-event {
                    @extend .justify_content_center;
                    margin: 0;
                    &:hover {
                      background-color: transparent;
                    }
                  }
                }
              }
              .online_event_box {
                border-radius: 0.8rem;
                border: 1.5px solid $btn_disable;
                padding: 1.5rem;
                margin-bottom: 1.5rem;

                .online_event_txt {
                  background-color: $light_blue;
                  color: $tab-head;
                  padding: 0.3rem 0.9rem;
                  border-radius: 0.5rem;
                  @extend .d_inline_block;
                  @extend .montserrat_regular;
                  margin-bottom: 1.5rem;
                  margin-right: 1rem;
                }
                .income_txt {
                  @extend .montserrat_semibold;
                  color: $black-color;
                }
                .time_detail {
                  @extend .montserrat_regular;
                  color: $black-color;
                }
                .event_boxes_btns {
                  @extend .d_flex_between;
                  gap: 0.8rem;
                  margin-top: 1.5rem;
                  .btn_outline {
                    max-width: 15.7rem;
                    padding: 1.3rem 1.7rem;
                    @extend .d_flex_center;
                    border: 0.1rem solid $red-required;
                    font-size: $fs12;
                    &:hover {
                      background-color: $red-required;
                      color: $white-color;
                    }
                  }
                  .btn_primary {
                    max-width: 15.7rem;
                    padding: 1.1rem 1.7rem;
                    @extend .d_flex_center;
                    font-size: $fs12;
                    &:hover {
                      color: $white-color;
                    }
                  }
                }
                &.complete_box {
                  .completed_txt {
                    background: rgba(165, 255, 165, 0.6);
                    @extend .d_inline_block;
                    padding: 0.3rem 0.9rem;
                    @extend .montserrat_regular;
                    border-radius: 0.5rem;
                    color: $tab-head;
                    margin-right: 1rem;
                  }
                  .task_title {
                    @extend .montserrat_semibold;
                    color: $black-color;
                    margin-top: 1.5rem;
                  }
                  .hashtag_detail {
                    @extend .montserrat_regular;
                    color: $black-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// full calendar style
.full_calendar_view {
  @include media_query(small) {
    margin-top: 13rem;
  }
  .calendar_sub_header {
    @extend .d_flex_between;
    padding: 1.6rem 3.2rem;
    @include media_query(small) {
      flex-wrap: wrap;
      padding: 1.6rem;
      white-space: nowrap;
    }
    h5 {
      @extend .montserrat_medium;
    }
    .added_btn_group {
      gap: 1.2rem;
      @include media_query(small) {
        margin-top: 1.6rem;
      }
      .btn_primary {
        max-width: 19.8rem;
        padding: 1rem 2.4rem;
        gap: 1.2rem;
        @include media_query(md_small) {
          font-size: $fs14;
        }
        &:hover {
          color: $white-color;
        }
        &.add_remind_btns {
          max-width: 19.8rem;
        }
      }
    }
  }
  .demo-app {
    @extend .d_block;
    @include media_query(large) {
      display: none;
    }
  }
  .demo-app-main {
    select {
      font-size: $fs16;
      padding: 1rem 2rem;
      &#month {
        appearance: none;
        background: url(../images/Polygon_calendar.svg) no-repeat;
        background-position: 70%;
        @extend .cursor_pointer;
        @extend .montserrat_medium;
        padding: 1rem 2rem 1rem 0.5rem;
        margin-right: 2rem;
      }
      &#year {
        appearance: none;
        background: url(../images/Polygon_calendar.svg) no-repeat;
        background-position: 100%;
        @extend .cursor_pointer;
        @extend .montserrat_medium;
        padding: 1rem 2rem 1rem 0.5rem;
      }
    }
    .fc-media-screen {
      font-size: $fs16;
      @extend .montserrat_semibold;
      &.fc-theme-standard {
        td {
          border: 0.1rem solid $btn_disable !important;
          background: transparent !important;
        }
      }
      .fc-event {
        align-items: baseline;
        white-space: break-spaces;
        @include media_query(one_extra_large) {
          white-space: break-spaces;
        }
        &:hover {
          background-color: transparent;
        }
        .fc-daygrid-event-dot {
          border-color: #1773e3;
        }
        &.reminder_dot {
          .fc-daygrid-event-dot {
            border-color: $saffron_yellow;
          }
        }
      }
    }
    .fc-daygrid-day-events {
      top: 0;
      font-size: $fs14;
      overflow: auto;
      height: 12rem;
    }
    .fc-daygrid-day-frame {
      // max-height: 5rem;
      @extend .h_100;
      // overflow: auto;
      padding: 1rem;
    }
  }
  .demo-app-main {
    select {
      font-size: $fs16;
      padding: 1rem 2rem;
      &#month {
        appearance: none;
        background: url(../images/Polygon_calendar.svg) no-repeat;
        background-position: 100%;
      }
      &#year {
        appearance: none;
        background: url(../images/Polygon_calendar.svg) no-repeat;
        background-position: 100%;
      }
    }

    .fc-media-screen {
      font-size: $fs16;
      @extend .montserrat_semibold;
      &.fc-theme-standard {
        td {
          border: 0.1rem solid $btn_disable !important;
        }
      }
      .fc-event {
        &:hover {
          background-color: transparent;
        }
        .fc-daygrid-event-dot {
          border-color: #1773e3;
        }
        &.reminder_dot {
          .fc-daygrid-event-dot {
            border-color: $saffron_yellow;
          }
        }
      }
    }
  }
}
.calendar_none {
  .chat_calendar {
    @extend .d_none;
    @include media_query(large) {
      display: block;
    }
    .common_title_detail {
      @include media_query(large) {
        display: none;
      }
    }
    .demo-app {
      @include media_query(small) {
        margin: 0 auto;
      }
    }
  }
}
// home notification pop
.home_notification_sec {
  @extend .position_absolute;
  top: 1.2rem;
  right: -5rem;
  @extend .d_flex;
  @extend .align_items_start;
  @extend .justify_content_end;
  @extend .w_100;
  width: 55.8rem;
  z-index: 9;
  @extend .border_0;
  @extend .p_0;
  @include media_query(large) {
    right: -27rem;
  }
  @include media_query(medium) {
    right: -26rem;
  }
  @include media_query(small) {
    right: -9rem;
    width: 40rem;
  }
  @include media_query(extra_small) {
    width: 35rem;
  }
  .notification_header {
    border: 0.1rem solid $btn_disable;
    border-bottom: transparent;
    border-radius: 0.8rem;
    box-shadow: 0 0.4rem 4rem 0 $box_shadow;
    background-color: $white-color;
    animation: popupRoll 0.6s ease-in-out;
    @extend .w_100;
    @include media_query(md_small) {
      max-width: 40rem;
    }
    .notification_txt_link {
      @extend .d_flex;
      @extend .justify_content_between;
      padding: 2.5rem;
      a {
        font-size: $fs16;
        color: $btn_primary;
        @extend .montserrat_medium;
      }
    }
    .notification_scroll {
      overflow-y: auto;
      max-height: 72rem;
      overflow-x: hidden;
      scrollbar-width: thin;
      scrollbar-color: $scroll-color $form-input-active-bg;
      border: 0.1rem solid $btn_disable;
      border-radius: 0.8rem;
      box-shadow: 0 4px 40px rgba(36, 73, 137, 0.08);
      @include media_query(small) {
        max-height: 66rem;
      }
    }
    .notification_list_box {
      @extend .d_flex;
      @extend .align_items_start;
      background: rgba(214, 226, 245, 0.2);
      padding: 2.5rem;
      border-bottom: 0.1rem solid $btn_disable;
      @include media_query(small) {
        padding: 1.6rem;
      }
      &.bg_remove {
        background: transparent;
      }
      &:last-child {
        border-bottom: transparent;
      }
      .notification_small_img {
        @extend .w_100;
        max-width: 5.5rem;
        height: 5.5rem;
        background-color: $project_bg;
        border-radius: 5rem;
        flex-shrink: 0;
        @extend .d_flex;
        img {
          @extend .w_100;
          @extend .h_100;
          @extend .img_object_center;
          border-radius: 50%;
        }
      }
      .notification_small_img {
        &.green_bg {
          background-color: rgba(41, 135, 0, 0.1);
        }
        &.red_bg {
          background-color: #fff1f1;
        }
        .approve_noti_icon {
          @extend .w_100;
          max-width: 2.4rem;
          height: 2.4rem;
          margin: auto;
        }
        .decline_icon {
          @extend .w_100;
          max-width: 2.4rem;
          height: 2.4rem;
          margin: auto;
        }
      }
      .home_noti_logo_icon {
        @extend .w_100;
        max-width: 3rem;
        height: 3rem;
        margin: auto;
      }
      .reminder_noti_icon {
        @extend .w_100;
        max-width: 2.4rem;
        height: 2.4rem;
        margin: auto;
        img {
          @extend .w_100;
          @extend .h_100;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .admin_post_list {
        padding-left: 3.6rem;
        padding-right: 1rem;
        @include media_query(small) {
          padding-left: 1.6rem;
          padding-right: 0.5rem;
        }
        @include media_query(extra_small) {
          width: 100%;
          max-width: 23rem;
        }
        .event_approved {
          color: $form_bdr;
          @extend .montserrat_bold;
        }
        .event_reject {
          color: $light_red;
        }
        .reminder {
          color: $btn_primary;
        }
        .reminder_content {
          @extend .montserrat_bold;
          color: $btn_primary;
          @include media_query(small) {
            font-size: $fs14;
          }
        }
        .bold_txt {
          @extend .montserrat_semibold;
          color: $black-color;
        }
        p {
          color: $black-color;
          font-size: $fs16;
          @extend .montserrat_regular;
          @include media_query(small) {
            font-size: $fs14;
          }
          span {
            word-break: break-word;
            &.noti_dot {
              @extend .w_100;
              max-width: 1rem;
              height: 1rem;
              border-radius: 5rem;
              background: $gray-two;
              @extend .d_inline_block;
              margin-left: 1.5rem;
              margin-right: 1rem;
            }
          }
        }
        .btn_outline {
          &:hover {
            background-color: $red-required;
          }
        }
      }
      .festival_details {
        border: 0.1rem solid $btn_disable;
        border-radius: 0.8rem;
        padding: 1.5rem 2.4rem;
        margin-top: 2rem;
        @extend .w_100;
        max-width: 37.3rem;
        .festival_time {
          color: $btn_primary;
          font-size: $fs16;
          @extend .montserrat_medium;
        }
        .festival_img {
          @extend .w_100;
          max-width: 8.2rem;
          height: 5rem;
          margin: 1rem 0;
          img {
            @extend .w_100;
            @extend .h_100;
            object-fit: cover;
          }
        }
        .city_txt {
          font-size: $fs18;
          @extend .montserrat_semibold;
          color: $gray-two;
        }
      }
      .invite_btns {
        margin-top: 1.5rem;
        gap: 0.8rem;
        @extend .d_flex;
        .btn_outline {
          max-width: 11.2rem;
          padding: 0.8rem 1.5rem;
          font-size: 1.1rem;
          height: 4.5rem;
          border: 0.2rem solid $light_red;
          &:hover {
            color: $white-color;
          }
        }
        .btn_primary {
          max-width: 11.2rem;
          padding: 0.8rem 1.7rem;
          font-size: 1.1rem;
          height: 4.5rem;
          @extend .justify_content_center;
          &:hover {
            color: $white-color;
          }
          &.min {
            background-color: $btn_primary;
            color: $white-color;
          }
          &.exp {
            max-width: 18rem;
            background-color: rgba(19, 115, 231, 0.1);
            color: $btn_primary;
          }
        }
        button {
          font-size: $fs12;
        }
        .btn {
          &.danger_btn {
            &.min {
              min-width: 7.7rem;
              background-color: transparent;
              border: 0.1rem solid $light_red;
              color: $black-color;
              &:hover {
                color: $white-color;
              }
              &:after {
                background-color: $light_red;
              }
            }
            &.exp {
              background-color: rgba(221, 32, 37, 0.1);
              color: $light_red;
              border-color: rgba(221, 32, 37, 0);

              &:hover {
                background-color: $light_red;
                color: $white-color;
              }
              &:after {
                background-color: $light_red;
              }
            }
          }
        }
      }
      .blue_dot_noti {
        @extend .w_100;
        max-width: 1rem;
        height: 1rem;
        border-radius: 5rem;
        background: $btn_primary;
        @extend .d_inline_block;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
      }
      .click_border_bottom {
        text-decoration: underline;
      }
    }
    .home_noti_loader {
      padding: 2.5rem;
      .animate-spin {
        margin: auto;
      }
    }
    .notification_festival_list {
      border: 0.1rem solid $btn_disable;
      .notification_list_box {
        background-color: transparent;
        align-items: start;

        .admin_details {
          padding-left: 3.6rem;
          .admin_post_list {
            @extend .p_0;
          }
        }
      }
    }
    h5 {
      color: $black-color;
    }
  }
}
// setting popup
.modal_comman {
  .delete_modal {
    &.setting_modal {
      max-width: 85.1rem;
      @include media_query(large) {
        max-width: 50rem;
      }
      @include media_query(small) {
        max-width: 40rem;
      }
      .close_btn {
        top: 2rem;
        @include media_query(small) {
          top: 1rem;
        }
      }
      .setting_list {
        margin-bottom: 3.2rem;
        margin-left: -3.2rem;
        margin-right: -3.2rem;
        @include media_query(large) {
          overflow-y: auto;
          max-height: 60rem;
          height: 100%;
          margin: 0;
        }
        @include media_query(small) {
          max-height: 55rem;
        }
        @include media_query(extra_small) {
          max-height: 45rem;
        }

        ul {
          @extend .p_0;
          li {
            @extend .m_0;
            form {
              .input_fild_remove {
                right: 1.6rem;
                @extend .position_absolute;
                @extend .cursor_pointer;
                top: 4.5rem;
                &.setting_popup_close_icon {
                  // right: 4.4rem;
                  right: 1.5rem;
                  top: 4.5rem;
                  background-color: $white-color;
                }
              }
              .show_error_msg {
                @extend .text_left;
                @extend .d_block;
                margin-top: 0.5rem;
              }
              .per_info {
                background: $table-hover;
                border-bottom: 0.1rem solid $btn_disable;
                gap: 1rem;
                min-height: 7.2rem;
                padding: 0 3.2rem;
                @extend .d_flex_between;
                @extend .w_100;
                .per_detials {
                  @extend .d_flex_align;
                  gap: 1rem;
                  .per_pic {
                    height: 2.5rem;
                    width: 2.5rem;
                  }
                  .per_name {
                    font-size: $fs16;
                    color: $gray-two;
                    @extend .montserrat_medium;
                  }
                }
                .set_arrow {
                  @extend .d_flex_center;
                  @extend .cursor_pointer;
                  img {
                    height: 2.4rem;
                    width: 2.4rem;
                  }
                  &.close {
                    img {
                      -webkit-transform: rotate(180deg);
                      transform: rotate(180deg);
                    }
                  }
                }
              }
              .persnol_details {
                .per_detais {
                  gap: 3.2rem;
                  padding: 3.2rem 3.2rem 0;
                  align-items: baseline;
                  @include media_query(large) {
                    flex-wrap: wrap;
                  }
                  .form_group {
                    &:first-child {
                      margin-bottom: 0;
                    }
                    input{
                      padding-right: 6.6rem;
                    }
                  }
                  &.email_requrd {
                    padding-top: 0;
                    .email_fild {
                      .form_control {
                        background: $form-input-active-bg;
                        border: 0.1rem solid $thub_border;
                        &::placeholder {
                          color: $gray-three;
                        }
                      }
                    }
                  }
                  .form_group {
                    @extend .w_100;
                    label {
                      @extend .text_left;
                      color: $black-color;
                      margin-bottom: 0.8rem;
                    }
                    &.phone_fild {
                      max-width: 29.2rem;
                      @include media_query(large) {
                        max-width: 100%;
                      }
                      .selected-dial-code {
                        top: 4.3rem;
                      }
                    }
                  }
                  .eye_pass {
                    @extend .position_absolute;
                    @extend .cursor_pointer;
                    // right: 1.6rem;
                    right: 3.5rem;
                    top: 5rem;
                    transform: translateY(-40%);
                    background-color: $white-color;
                  }
                }
              }
            }
          }
        }
      }
      .upload_btn {
        margin-top: 3.2rem;
        .primary_btn {
          background: $btn_primary;
          color: $white-color;
          border: transparent;
          font-size: $fs16;
          &:hover {
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
          }
        }
      }
    }
  }
}

@keyframes popupRoll {
  0% {
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  100% {
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }
}

.main_update_profile {
  border-radius: 1.6rem;
  > div{
    @include media_query(large) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .left_sec {
    .setting_profile {
      border-radius: 6.7rem;
      border: 0.2rem solid #b6d4f8;
      background: #dceafb;
      width: 8.8rem;
      height: 8.8rem;
      img{
        @extend .w_100;
        @extend .h_100;
        @extend .img_object_center;
        @extend .radius_round;
        
      }
    }
    .content {
      p {
        color: $black-color;
        max-width: 25.5rem;
        @extend .text_center;

        @include media_query(extra_small) {
          font-size: $fs12;
        }
      }
    }
    input [type="file"] {
      top: 5rem;
      @extend .cursor_pointer;
    }
    @include media_query(large) {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 2.4rem;
    }
  }
  .right_sec {
    .button_upload{
      @extend .cursor_pointer;
      button {
        min-width: 22.9rem;
        gap: 1.2rem;
        @extend .cursor_pointer;
        @include media_query(extra_small) {
          min-width: 100%;
          width: 100%;
          font-size: 1.2rem;
        }
        &:hover {
          > img {
            filter: brightness(21);
            @include trans;
          }
        }
        
      }
      &:hover{
        button{
          background-color: #1773e3;
          color: $white-color;
          img{
            filter: brightness(21);
            @include trans;
          }
          &.btn_remove{
            background-color: transparent;
            color: #dd2025;
          }
        }
      }
      }
      
      .btn_remove {
        color: #dd2025;
        @extend .text_center;
        @extend .montserrat_bold;
        @extend .fs_16;
        line-height: 150%;
        &:hover{
          background-color: transparent;
          color: #dd2025;
        }
      }
  }
}
